/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState,useEffect} from "react";
import {useDropzone} from 'react-dropzone';
import * as R from 'ramda';
import Global from "constants/Global";


import {Table,TableBody,TableCell,Paper,styled,Button,Divider
    ,TableContainer,TableHead,TableRow,FormGroup,FormControlLabel }from '@material-ui/core';

import {Box} from '@material-ui/core';
import { withTranslation } from 'react-i18next';


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.common.white,
    }
  }));


const Index = (props) => {
    const {t,datas,token} = props;
    var filesTransit=[],found=false,newFiles=[],file
    const [isLoading, setIsLoading] = useState(false)

    const [infoAVI, setInfoAVI] = useState({
        isActive:false,
        files:[]
    });
    const [infoBedroom, setInfoBedroom] = useState({
        isActive:false,
        files:[ ]
    });
    const [infoAirTicket, setInfoAirTicket] = useState({
        isActive:false,
        files:[ ]
    });
    const [infoAirportWelcome, setInfoAirportWelcome] = useState({
        isActive:false,
        files:[ ]
    });
    const [infoHostFamily, setInfoHostFamily] = useState({
        isActive:false,
        files:[ ]
    });

    const {getRootProps, getInputProps} = useDropzone({
        onDrop :acceptedFiles => {
            if(datas.myFollowUp.type=="avi"){
                filesTransit=infoAVI.files
                acceptedFiles.map((itm)=>{
                    filesTransit.push(itm)
                })
                setInfoAVI({...infoAVI,files: filesTransit})
            }
        }
      });
    
  
    useEffect(() => {
        if(datas.myFollowUp.type=="avi"){
            if(datas.myFiles) setInfoAVI({...infoAVI,files: R.filter(o => o.type === datas.myFollowUp.type, datas.myFiles)})
        }
    }, []);

    const showComponemt=()=>{
        if(datas.myFollowUp.type=="avi")return (<div> { componentAVI()}</div>)
        if(datas.myFollowUp.type=="bedroom")return (<div> { componentBedroom()}</div>)
        if(datas.myFollowUp.type=="airTicket")return (<div> { componentAirTicket()}</div>)
        if(datas.myFollowUp.type=="airportWelcome")return (<div> { componentAirportWelcome()}</div>)
        if(datas.myFollowUp.type=="hostFamily")return (<div> { componentHostFamily()}</div>)
    }

    const updateElt=()=>{
        if(datas.myFollowUp.type==="avi"){
            newFiles=[]
            for (let i = 0; i < infoAVI.files.length; i++) {
                file=infoAVI.files[i]
                found=false
                if(datas.myFiles){
                    for (let j = 0; j < datas.myFiles.length; j++) {
                        if(datas.myFiles[j].title==infoAVI.files[i].title){
                            found=true;
                            break;
                        }
                    }
                }
                if(!found)newFiles.push(file)
            }

            for (let i = 0; i < newFiles.length; i++) {
                file = new FormData();
                file.append('file',newFiles[i]);
                file.append('type',"avi");
                file.append('candidate_id', datas._id);
                file.append('token',token);
                
                setIsLoading(true)
                fetch(Global.SERVER_URL + '/addFile', {
                    method: 'POST',
                    body: file
                }).then(response => response.json())
                .then(responseData => {
                if (responseData.hasOwnProperty("error")){
                    setIsLoading(false)
                    if (responseData.error === 200){
                    }else NotificationManager.error(responseData.msg, 'Message');
                }
                setIsLoading(false)
                }, (error) => {
                if (error) {
                    NotificationManager.error(t('unknown_error')+" :: ", 'Message');
                    setIsLoading(false)
                }
                });
            }
        }
    }

    const componentAVI=()=>{
        return(<Box>
            <h2 className="mb-0">Informations AVI</h2>
            <br/>
            <section className="container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {infoAVI.files.length!=0?<TableHead>
                            <StyledTableRow>
                                <TableCell>{t("title_document")}</TableCell>
                                <TableCell align="right"></TableCell>
                            </StyledTableRow>
                        </TableHead>:null}
                        <TableBody>
                            {infoAVI.files.map((row) => (
                                <TableRow
                                key={row.hasOwnProperty("path")?row.path:row.title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <a target="_blank" href={""+Global.SERVER_URL+"/cases/"+datas._id+"/"+(row.hasOwnProperty("path")?row.path:row.title)}>{row.hasOwnProperty("path")?row.path:row.title}</a>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>{
                                                if(row.hasOwnProperty("path"))setInfoAVI({...infoAVI,files:R.filter(o => o.path !== row.path, infoAVI.files)});
                                                else setInfoAVI({...infoAVI,files:R.filter(o => o.title !== row.title, infoAVI.files)});
                                               
                                                fetch(Global.SERVER_URL + '/deleteFile', {
                                                    method: 'POST',
                                                    headers: {
                                                      Accept: "application/json",
                                                      "Content-Type": "application/json",
                                                      'Access-Control-Allow-Origin': '*'
                                                    },
                                                    body: JSON.stringify({token,candidate_id:datas._id,file:row.path?row.path:row.title})
                                                  }).then(response => response.json())
                                                  .then(responseData => {
                                                    if (responseData.hasOwnProperty("error")){
                                                      if (responseData.error === 200){
                                                      }else NotificationManager.error(responseData.msg, 'Message');
                                                    }
                                                  }, (error) => {
                                                }, (error) => {if (error)  NotificationManager.error(t('unknown_error')+" :: ", 'Message');});

                                            }}
                                        >{t("delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div className="dropzone-msg dz-message needsclick">
                        <i className="fas fa-cloud-upload-alt" />
                        <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                    </div>
                </div>
                <br/>
                {isLoading?
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled
                >loading...</Button>:
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>{updateElt()}}
                >{t("update")}</Button>}
            </section>
        </Box>)
    }
    const componentBedroom=()=>{
        return(<Box mt={2}>
            <h2 className="mb-0">Informations AVI</h2>
            <br/>
            <section className="container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>{t("title_document")}</TableCell>
                                <TableCell align="right"></TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {infoAVI.files.map((row) => (
                                <TableRow
                                key={row.title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>{
                                                setFilesAdd(R.filter(o => o.title !== row.title, filesAdd));
                                            }}
                                        >{t("delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div className="dropzone-msg dz-message needsclick">
                        <i className="fas fa-cloud-upload-alt" />
                        <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                    </div>
                </div>
                <br/>
                {files.length>0?<aside>
                    <h4>Documents</h4>
                    <ul>{files}</ul>
                </aside>:null}
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>{
                    
                    }}
                >{t("update")}</Button>
            </section>
        </Box>)
    }
    const componentAirTicket=()=>{
        return(<Box mt={2}>
            <h2 className="mb-0">Informations AVI</h2>
            <br/>
            <section className="container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>{t("title_document")}</TableCell>
                                <TableCell align="right"></TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {infoAVI.files.map((row) => (
                                <TableRow
                                key={row.title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>{
                                                setFilesAdd(R.filter(o => o.title !== row.title, filesAdd));
                                            }}
                                        >{t("delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div className="dropzone-msg dz-message needsclick">
                        <i className="fas fa-cloud-upload-alt" />
                        <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                    </div>
                </div>
                <br/>
                {files.length>0?<aside>
                    <h4>Documents</h4>
                    <ul>{files}</ul>
                </aside>:null}
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>{
                    
                    }}
                >{t("update")}</Button>
            </section>
        </Box>)
    }
    const componentAirportWelcome=()=>{
        return(<Box mt={2}>
            <h2 className="mb-0">Informations AVI</h2>
            <br/>
            <section className="container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>{t("title_document")}</TableCell>
                                <TableCell align="right"></TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {infoAVI.files.map((row) => (
                                <TableRow
                                key={row.title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>{
                                                setFilesAdd(R.filter(o => o.title !== row.title, filesAdd));
                                            }}
                                        >{t("delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div className="dropzone-msg dz-message needsclick">
                        <i className="fas fa-cloud-upload-alt" />
                        <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                    </div>
                </div>
                <br/>
                {files.length>0?<aside>
                    <h4>Documents</h4>
                    <ul>{files}</ul>
                </aside>:null}
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>{
                    
                    }}
                >{t("update")}</Button>
            </section>
        </Box>)
    }
    const componentHostFamily=()=>{
        return(<Box mt={2}>
            <h2 className="mb-0">Informations AVI</h2>
            <br/>
            <section className="container">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>{t("title_document")}</TableCell>
                                <TableCell align="right"></TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {infoAVI.files.map((row) => (
                                <TableRow
                                key={row.title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>{
                                                setFilesAdd(R.filter(o => o.title !== row.title, filesAdd));
                                            }}
                                        >{t("delete")}</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div className="dropzone-msg dz-message needsclick">
                        <i className="fas fa-cloud-upload-alt" />
                        <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                    </div>
                </div>
                <br/>
                {files.length>0?<aside>
                    <h4>Documents</h4>
                    <ul>{files}</ul>
                </aside>:null}
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>{
                    
                    }}
                >{t("update")}</Button>
            </section>
        </Box>)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {showComponemt()}
        </Box>
        
    );
};
export default withTranslation()(Index);
