/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withTranslation } from 'react-i18next';

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputPass from "@material-ui/core/Input";
import {encode as base64_encode} from 'base-64';
// reactstrap components
import {
  Card,CardHeader,CardBody,
  FormGroup,Form,
  Input,
  Container,
  Row,Button,
  Col,
} from "reactstrap";
import  "../styles/timeline.css";
import UserHeader from "components/Headers/UserHeader.js";
import SidebarProfile from "components/Sidebar/SidebarProfile.js";
import Global from '../../constants/Global';


const Profile = (props) => {
  const { t } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [myInfos, setMyInfos] = useState({firstname:" "})
  const [myStat, setMyStat] = useState({case:0,country:0,admission:0})
  const [values, setValues] = useState({showPassword:false,showPassword_:false})

  useEffect(() => {
    document.title = t("my_profile")+" :: YimmiLine"
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setMyInfos(JSON.parse(localStorage[key]))
        setIsLoading(true)
        fetch(Global.SERVER_URL + '/getMyStat', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({token:JSON.parse(localStorage[key]).token})
        }).then(response => response.json())
          .then(responseData => {
            if(responseData.hasOwnProperty("error")){
              if(responseData.error===200)setMyStat(responseData.myStat);
            }
            setIsLoading(false)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }
          });
      }
  }, []);

  const updateInfos=()=>{
    if(myInfos.lastname===""){
      NotificationManager.error(t("lastname"), t("check_field"));
    }
    else if(myInfos.email===""){
      NotificationManager.error(t("email"), t("check_field"));
    }
    else if(myInfos.phone===""){
      NotificationManager.error(t("phone"), t("check_field"));
    }
    else{
      var data=myInfos;
      delete myInfos.new_password
      delete myInfos.old_password
      delete myInfos.password

      setIsLoading(true)
      fetch(Global.SERVER_URL + '/updateUserInfos', {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      }).then(response => response.json())
        .then(responseData => {
          if (responseData.hasOwnProperty("error")) {
            if (responseData.error === 200) {
              console.log("1.....");
              localStorage.clear()
              console.log("2.....");
              localStorage.setItem('myInfos-'+(new Date()).getTime(), JSON.stringify(responseData.user))
              console.log("3.....");

              console.log("responseData.user",responseData.user);
              NotificationManager.success(t('successful_operation'), 'Message');
            }
            else NotificationManager.error(t('unknown_error'), 'Message');
          }
          else NotificationManager.error(t('unknown_error'), 'Message');
          setIsLoading(false)
        }, (error) => {
          if (error) {
            NotificationManager.error(t('unknown_error')+" :: ", 'Message');
            setIsLoading(false)
          }
        });
    }
  }

  const updatePassword=()=>{
    if(!myInfos.old_password){
      NotificationManager.error(t("old_password"), t("check_field"));
    }
    else if(!myInfos.new_password){
      NotificationManager.error(t("new_password"), t("check_field"));
    }
    else{
      if(myInfos.old_password===""){
        NotificationManager.error(t("old_password"), t("check_field"));
      }
      else if(myInfos.new_password===""){
        NotificationManager.error(t("new_password"), t("check_field"));
      }
      else{
        var data={
          new_password:base64_encode(myInfos.new_password),
          old_password:base64_encode(myInfos.old_password),
          token:myInfos.token
        };

        setIsLoading(true)
        fetch(Global.SERVER_URL + '/updatePassword', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(data)
        }).then(response => response.json())
          .then(responseData => {
            if (responseData.hasOwnProperty("error")) {
              if (responseData.error === 200) NotificationManager.success(t('successful_operation'), 'Message');
              else NotificationManager.error(responseData.msg , 'Message');
            } else NotificationManager.error(t('unknown_error'), 'Message');
            setIsLoading(false)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error')+" :: ", 'Message');
              setIsLoading(false)
            }
          });
      }
    }
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('treatment_progress') + " , " + t('wait_just_moment')}
      >
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Mon compte</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">{t("my_infos")}</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("firstname")} </label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("your_firstname")}
                              type="text"
                              value={myInfos.firstname}
                              onChange={(val) => { setMyInfos({ ...myInfos, "firstname": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                            {t("lastname")}*
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("your_lastname")}
                              type="text"
                              value={myInfos.lastname}
                              onChange={(val) => { setMyInfos({ ...myInfos, "lastname": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("address_mail")}*</label>
                            <Input
                              className="form-control-alternative"
                              type="email"
                              placeholder="johnDeo@yimmilen.com"
                              value={myInfos.address_mail}
                              onChange={(val) => { setMyInfos({ ...myInfos, "address_mail": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("phone")}*
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("your_phone")}
                              type="phone"
                              value={myInfos.phone}
                              onChange={(val) => { setMyInfos({ ...myInfos, "phone": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {myInfos.role==="user"?<Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-sponsor"
                            >
                              Nom(s) du sponsor
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-sponsor"
                              placeholder="Nom(s) parent / tuteur"
                              type="text"
                              onChange={(val) => { setMyInfos({ ...myInfos, "guardian": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-sponsor-phone"
                            >
                              Téléphone du sponsor
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-sponsor-phone"
                              placeholder="Téléphone"
                              type="phone"
                              onChange={(val) => { setMyInfos({ ...myInfos, "guardian_contact": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>:null}
                      {myInfos.role==="user"?<Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >{t("place_residence")}</label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder={t("place_residence")}
                              type="text"
                              onChange={(val) => { setMyInfos({ ...myInfos, "place_residence": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>:null}
                      
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    {myInfos.role==="user"?<div>
                      <h6 className="heading-small text-muted mb-4">
                        {t("training")}
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-diplome"
                              >{t("last_diploma_obtained")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-diplome"
                                placeholder={t("last_diploma_obtained")}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-date"
                              >{t("date_graduation")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-date"
                                placeholder={t("date_graduation")}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                {t("targeted_training")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder={t("targeted_training")}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>:null}
                    <hr className="my-4" />
                    {/* Description */}
                    {myInfos.role==="user"?<div>
                    <h6 className="heading-small text-muted mb-4">Mes attentes</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="Mes attentes ..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </div></div>:<div>
                    <h6 className="heading-small text-muted mb-4">{t("skill")}</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                          <Input type="select" multiple 
                            value={myInfos.listCountries}
                            onChange={(val) => { 
                              let value = Array.from(val.target.selectedOptions, option => option.value);
                              setMyInfos({...myInfos,'listCountries':value}) }} >
                              {Global.listCountries.map((country)=>{
                                return (<option value={country}>{country}</option>)
                              })}
                          </Input>
                          <p className="mt-3 mb-0 text-muted text-sm">
                              <span className="text-nowrap">{t("CTRL_click")}</span>
                          </p>
                        </FormGroup>
                    </div></div>}
                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button outline color="primary" onClick={()=>{updateInfos()}}>{t("modify")}</Button>
                      </Col>
                     </Row>
                  </Form>
                </CardBody>
              </Card>
              <br/>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{t("security")}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("old_password")} </label>
                            <InputPass
                              type={values.showPassword ? "text" : "password"}
                              onChange={(val) => { setMyInfos({ ...myInfos, "old_password": val.target.value }) }}
                              value={myInfos.old_password}
                              placeholder={t("old_password")} 
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => { setValues({ ...values, showPassword: !values.showPassword });}}
                                    onMouseDown={(event) => {event.preventDefault();}}
                                  >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("new_password")} </label>
                            <InputPass
                              type={values.showPassword_ ? "text" : "password"}
                              onChange={(val) => { setMyInfos({ ...myInfos, "new_password": val.target.value }) }}
                              value={myInfos.new_password}
                              placeholder={t("new_password")} 
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => { setValues({ ...values, showPassword_: !values.showPassword_ });}}
                                    onMouseDown={(event) => {event.preventDefault();}}
                                  >
                                    {values.showPassword_ ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                          <Button outline color="primary" onClick={()=>{updatePassword()}}>{t("modify")}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {myInfos.hasOwnProperty("role")?<SidebarProfile 
              myStat={myStat}
            />:null}
          </Row>
          <NotificationContainer />
        </Container>
      </LoadingOverlay>
    </>
  );
};
export default withTranslation()(Profile);
