/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState,useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import { withTranslation } from 'react-i18next';
import Global from '../../constants/Global';
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import moment from 'moment';
import * as R from 'ramda';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,PaginationLink,
  Container,PaginationItem,  
  Row,Table,Pagination, 
  Col,Button} from 'reactstrap';
  
import  "../styles/timeline.css";
import UserHeader from "components/Headers/UserHeader.js";
import SidebarProfile from "components/Sidebar/SidebarProfile.js";

const MyFiles = (props) => {
  const { t } = props;
  const pageSize=3;
  const [isLoading, setIsLoading] = useState(false)
  const [myInfos, setMyInfos] = useState({token:" "})
  const [itemsTable, setItemsTable] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  const [myStat, setMyStat] = useState({case:0,country:0,admission:0})
  const [file, setFile] = useState({
    title:"",
    date:new Date(),
    file:null
  });
  const [details, setDetails] = useState({
    last_diploma_obtained:"",
    targeted_training:"",
    date_graduation:new Date(),
    my_expectations:""
  });
  var _myInfos={}

  useEffect(() => {
    document.title = t('my_cases')+" :: YimmiLine"
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        _myInfos=JSON.parse(localStorage[key])
        setMyInfos(_myInfos)
        if(_myInfos.hasOwnProperty("myFiles")){
          var myFiles=R.sort(function(a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime(); }, _myInfos.myFiles);
          setItemsTable(myFiles)
        }
        if(_myInfos.hasOwnProperty("target")){
          setDetails(_myInfos.target)
        }


        setIsLoading(true)
        fetch(Global.SERVER_URL + '/getMyStat', {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({
              token:JSON.parse(localStorage[key]).token,
            })
        }).then(response => response.json())
        .then(responseData => {
          if (responseData.hasOwnProperty("error")){
            if (responseData.error === 200){
              setMyStat(responseData.myStat)
            }
          }
          setIsLoading(false)
        });
      }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFile({ ...file, 
        "file": acceptedFiles[0],
        "title": acceptedFiles[0].path.split(".")[0]
      })
    }
  });

  const uploadFile=()=>{
    if (file.title==="") {
      NotificationManager.error(t("title_document"), 'Message');
    }else if (file.file===null) {
      NotificationManager.error("Ajoutez un fichier", 'Message');
    }else{
      const data = new FormData();

      data.append('file',file.file);
      data.append('title', file.title);
      data.append('date', file.date);
      data.append('type',"myFile");
      data.append('token', myInfos.token);
      
      setIsLoading(true)
      fetch(Global.SERVER_URL + '/addFile', {
           method: 'POST',
           body: data
      }).then(response => response.json())
      .then(responseData => {
        if (responseData.hasOwnProperty("error")){
          if (responseData.error === 200){
            NotificationManager.success(t('successful_operation'), 'Message');
            _myInfos=myInfos
            _myInfos.myFiles=responseData.myFiles
            setMyInfos(_myInfos)
            localStorage.clear()
            localStorage.setItem('myInfos-'+(new Date()).getTime(), JSON.stringify(_myInfos))
          }else
            NotificationManager.error(responseData.msg, 'Message');
        }
        setIsLoading(false)
      }, (error) => {
        if (error) {
          NotificationManager.error(t('unknown_error')+" :: ", 'Message');
          setIsLoading(false)
        }
      });
    }
  }
  const uploadDetails=()=>{
    if (details.last_diploma_obtained==="") {
      NotificationManager.error(t("last_diploma_obtained"), 'Message');
    }else if (details.targeted_training==="") {
      NotificationManager.error(t("targeted_training"), 'Message');
    }else{
      setIsLoading(true)
      fetch(Global.SERVER_URL + '/updateTarget', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({token:myInfos.token,details})
      }).then(response => response.json())
      .then(responseData => {
        console.log(responseData);
        if (responseData.hasOwnProperty("error")){
          if (responseData.error === 200){
            NotificationManager.success(t('successful_operation'), 'Message');
            _myInfos=myInfos
            _myInfos.target=details
            setMyInfos(_myInfos)
            localStorage.clear()
            localStorage.setItem('myInfos-'+(new Date()).getTime(), JSON.stringify(_myInfos))
          }else
            NotificationManager.error(responseData.msg, 'Message');
        }
        setIsLoading(false)
      }, (error) => {
        console.log(error);
        if (error) {
          NotificationManager.error(t('unknown_error')+" :: ", 'Message');
          setIsLoading(false)
        }
      });
    }
  }
  
  const deleteFile=(file)=>{
    setIsLoading(true)
    fetch(Global.SERVER_URL + '/deleteFile', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token:myInfos.token,file})
    }).then(response => response.json())
    .then(responseData => {
      if (responseData.hasOwnProperty("error")){
        if (responseData.error === 200){
          NotificationManager.success(t('successful_operation'), 'Message');
          _myInfos=myInfos
          _myInfos.myFiles=responseData.myFiles
          setMyInfos(_myInfos)
          localStorage.clear()
          localStorage.setItem('myInfos-'+(new Date()).getTime(), JSON.stringify(_myInfos))
        
          var myFiles=R.sort(function(a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime(); }, _myInfos.myFiles);
          setItemsTable(myFiles)
        }else NotificationManager.error(responseData.msg, 'Message');
      }
      setIsLoading(false)
    }, (error) => {
      if (error) {
        NotificationManager.error(t('unknown_error')+" :: ", 'Message');
        setIsLoading(false)
      }
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('treatment_progress') + " , " + t('wait_just_moment')}
      >
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{t("my_documents")}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">{t("recorded_files")}</h6>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">{t("training")}</th>
                          <th scope="col">Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {itemsTable.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                        .map(doc => {
                          return (
                            <tr key={doc.title}>
                              <td>
                                <a className="btn btn-light" href={Global.SERVER_URL + '/cases/'+myInfos._id+"/"+doc.title}>{doc.title}</a>
                              </td>
                              <td>{doc.date?moment(doc.date).format("MMM/YYYY"):"Non défini"}</td>
                              <td><Button color="outline-danger" size="sm" onClick={()=>{deleteFile(doc.title)}}>{t("delete")}</Button></td>
                            </tr>
                          );
                      })
                      }
                      </tbody>
                    </Table>

                    <Pagination>
                      {[...Array(Math.round(itemsTable.length/pageSize)+1)].map((page, i) => (
                        <PaginationItem active={i === currentPage} key={i}>
                          <PaginationLink onClick={e => handlePageClick(e, i)} href="#">{i + 1}</PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                  
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">{t("add_file")}</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col sm="9">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >{t("title_document")}</label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder={t("title_document")}
                              type="text"
                              value={file.title}
                              onChange={(val) => { setFile({ ...file, "title": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              views={["year", "month"]}
                              label={t("month_year")}
                              helperText={t("publishing_date")}
                              minDate={new Date("1970")}
                              maxDate={new Date()}
                              value={file.date}
                              onChange={(val) => { setFile({ ...file, "date": val}) }}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      
                        <Col lg="12">
                          <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <div className="dropzone-msg dz-message needsclick">
                                  <i className="fas fa-cloud-upload-alt" />
                                  <h5 className="dropzone-msg-title">{t("click_upload_doc")}</h5>
                              </div>
                          </div>
                        </Col>
                        <Col lg="12">
                          <br/>
                          <Button color="primary" onClick={()=>{uploadFile()}}>{t("send")}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {myInfos.hasOwnProperty("role")?<SidebarProfile 
              myStat={myStat}
            />:null}
          </Row>
          <NotificationContainer />
        </Container>
      </LoadingOverlay>
    </>
  );
};
export default withTranslation()(MyFiles);