/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useEffect} from "react";
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// reactstrap components 
import {Container, Row } from "reactstrap";

const Header = (props) => {
  let history = useHistory();

  useEffect(() => {
    var found=false
    for (var key in localStorage){
        if(key.includes("myInfos")){
            found=true
        }
    }
    if(!found)history.push('/auth/login');
  },[]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default withTranslation()(Header);
