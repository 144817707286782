/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withTranslation } from 'react-i18next';
import {useDropzone} from 'react-dropzone'
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import SecondedServices from "components/FollowUp/SecondedServices";

// reactstrap components
import {
  Card,CardHeader,CardBody,
  FormGroup,Form,
  Input,
  Container,
  Row,Button,
  Col,PaginationLink,
  PaginationItem,Table,Pagination
} from "reactstrap";
import * as R from 'ramda';

import moment from 'moment';
import UserHeader from "components/Headers/UserHeader.js";
import SidebarProfile from "components/Sidebar/SidebarProfile.js";
import Global from '../../constants/Global';


const CandidateFollowUp = (props) => {
  const { t } = props;
  const pageSize=3;
  const pageSchoolSize=3;
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([]);

  const [file, setFile] = useState({
    title:"",
    name:"",
    date:new Date(),
    file:null
  });

  const [datas, setDatas] = useState({myFollowUp:{"type":""}})
  const [currentUser, setCurrentUser] = useState({})
  const [myStat, setMyStat] = useState({case:0,country:0,admission:0})
  const [progressInfo, setProgressInfo] = useState({status:-1,percent:-1,note:""})

  const [itemsTable, setItemsTable] = useState([])
  const [currentPage, setCurrentPage] = useState(0);

  const [schoolsTable, setSchoolsTable] = useState([]);
  const [currentSchoolPage, setCurrentSchoolPage] = useState(0);
  const [hideSection, setHideSection] = useState({
    section1:false,
    section2:true,
    section3:true,
    section4:true,
    section5:true,
  });

  const [schoolSection, setSchoolSection] = useState({
    follow_up_id:-1,
    school_id:-1,
    title:"",
    url:"",
    state:-1,
    username:"",
    password:"",
    any_additional_information:""
  });

  useEffect(() => {
    document.title = t('follow_up_update')+" :: YimmiLine"
    let routeState
    if(props.location.state){
      localStorage.setItem('routeState', JSON.stringify(props.location.state))
      routeState = props.location.state
    }else {
      routeState = localStorage.getItem('routeState')
      if(routeState) routeState = JSON.parse(routeState)
    }
    setSchoolSection({...schoolSection,follow_up_id:routeState.follow_up_id})
    
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setCurrentUser(JSON.parse(localStorage[key]))

        setIsLoading(true)
        fetch(Global.SERVER_URL + '/getFollowUpInfos', {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({
              token:JSON.parse(localStorage[key]).token,
              candidateID:routeState.user_id,
              follow_up_id:routeState.follow_up_id,
            })
        }).then(response => response.json())
        .then(responseData => {
          if (responseData.hasOwnProperty("error")){
            if (responseData.error === 200){

              if(responseData.candidate.hasOwnProperty("myFiles")){
                var myFiles=R.sort(function(a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime(); }, responseData.candidate.myFiles);
                setItemsTable(myFiles)
              }

              setDatas(responseData.candidate)
              setMyStat(responseData.myStat)
              if(responseData.candidate.hasOwnProperty("myFollowUp")){
                setProgressInfo({percent:responseData.candidate.myFollowUp.percent,status:responseData.candidate.myFollowUp.status})
                
                if(responseData.candidate.myFollowUp.type!="avi"){
                  if(responseData.candidate.myFollowUp.hasOwnProperty("schools")){
                    
                    var details_schools=[],detail_school={}
                    responseData.candidate.myFollowUp.schools.forEach(schools => {
                      detail_school={
                        id:schools.id,
                        any_additional_information:schools.any_additional_information,
                        password:schools.password,
                        title:schools.title,
                        username:schools.username,
                        url:schools.url,
                        state:schools.state,
                        files:[]
                      };
                      if(schools.hasOwnProperty("files"))
                        for (let i = 0; i < schools.files.length; i++) detail_school.files.push(schools.files[i]);
                      details_schools.push(detail_school)
                    });
                    setSchoolsTable(details_schools)
                  }
                }
              }
            }
          }
          setIsLoading(false)
        });
      }
  }, []);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };
  
  const handleShoolPageClick = (e, index) => {
    e.preventDefault();
    setCurrentSchoolPage(index);
  };

  const deleteFile=(file,school)=>{
    setIsLoading(true)
    fetch(Global.SERVER_URL + '/deleteSchoolFile', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        token:currentUser.token,
        candidateID:datas._id,
        follow_up_id:datas.myFollowUp._id,
        file,
        school
      })
    }).then(response => response.json())
    .then(responseData => {
      if (responseData.hasOwnProperty("error")){
        if (responseData.error === 200){
          NotificationManager.success(t('successful_operation'), 'Message');
          window.location.reload();
        }else NotificationManager.error(responseData.msg, 'Message');
      }
      setIsLoading(false)
    }, (error) => {
      if (error) {
        NotificationManager.error(t('unknown_error')+" :: ", 'Message');
        setIsLoading(false)
      }
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if(!hideSection.section3){
        setFile({ ...file, 
          "file": acceptedFiles[0],
          "title": acceptedFiles[0].path.split(".")[0]
        })
      }else if(!hideSection.section4){
        const updatedFiles = [];
        files.forEach(element => {updatedFiles.push(element)});
  
        acceptedFiles.forEach(element => {
          var found=false
          for (let i = 0; i < updatedFiles.length; i++) {
            if(updatedFiles[i].path===element.path){
              found=true
              break
            }
          }
          if(!found)updatedFiles.push(element)
        });
  
        setFiles(updatedFiles);
      }
    }
  });
  
  const listFiles = files.map(row => (
    <div key={row.path}>
      <Row >
        <Col sm="10">
            <p>{row.path}</p>
        </Col>
        <Col sm="2">
            <Button
              color="outline-danger"
              onClick={() =>{
                  const updatedFiles = [];
                  files.forEach(element => {
                      if(element.path!==row.path){
                          updatedFiles.push(element)
                      }
                  });
                  setFiles(updatedFiles);
              }}
            >
                {t("delete")}
            </Button>
        </Col>
      </Row>
      <br/>
    </div>
  ));

  const addSchool=()=>{
    if(schoolSection.title===""){
      NotificationManager.error(t('school_name'), t("check_field"));
    }else{
      setIsLoading(true)
      var data=schoolSection;
      data.token=currentUser.token
      data.candidateID=datas._id

      const _form = new FormData();
      for (let i = 0; i < files.length; i++) {
        _form.append('file_'+i,files[i]);
      }
      _form.append('info', JSON.stringify(data));

      fetch(Global.SERVER_URL + '/addSchool', {
        method: 'POST',
        body:_form
      }).then(response => response.json())
      .then(responseData => {
        if (responseData.hasOwnProperty("error")){
          if (responseData.error === 200){
            NotificationManager.success(t('successful_operation'), 'Message');
            window.location.reload();
          }else NotificationManager.error(responseData.msg, 'Message');
        }
        setIsLoading(false)
      }, (error) => {
        if (error) {
          NotificationManager.error("Message", );t('unknown_error')
          setIsLoading(false)
        }
      });
    }
  }

  const updateProgress=()=>{
    setIsLoading(true)
    fetch(Global.SERVER_URL + '/updateCandidateProgress', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        token:currentUser.token,
        candidateID:datas._id,
        follow_up_id:datas.myFollowUp._id,
        percent:progressInfo.percent,
        status:progressInfo.status
      })
    }).then(response => response.json())
    .then(responseData => {
      if (responseData.hasOwnProperty("error")){
        if (responseData.error === 200){
          NotificationManager.success(t('successful_operation'), 'Message');
        }else NotificationManager.error(responseData.msg, 'Message');
      }
      setIsLoading(false)
    }, (error) => {
      if (error) {
        NotificationManager.error(t('unknown_error')+" :: ", 'Message');
        setIsLoading(false)
      }
    });
  }

  const rowSchoolClick=(content)=>{
    setSchoolSection({...schoolSection,
      school_id:content.id,
      title:content.title,
      url:content.url,
      state:content.state,
      username:content.username,
      password:content.password,
      any_additional_information:content.any_additional_information
    })
  }

  const uploadFile=()=>{
    if (file.title==="") {
      NotificationManager.error(t("title_document"), 'Message');
    }else if (file.file===null) {
      NotificationManager.error("Ajoutez un fichier", 'Message');
    }else{
      const data = new FormData();

      data.append('file',file.file);
      data.append('title', file.name);
      data.append('date', file.date);
      data.append('candidate_id', datas._id);
      data.append('token', currentUser.token);
      
      setIsLoading(true)
      fetch(Global.SERVER_URL + '/addFile', {
           method: 'POST',
           body: data
      }).then(response => response.json())
      .then(responseData => {
        if (responseData.hasOwnProperty("error")){
          if (responseData.error === 200){
            NotificationManager.success(t('successful_operation'), 'Message');
            setItemsTable(responseData.myFiles)
          }else NotificationManager.error(responseData.msg, 'Message');
        }
        setIsLoading(false)
      }, (error) => {
        if (error) {
          NotificationManager.error(t('unknown_error')+" :: ", 'Message');
          setIsLoading(false)
        }
      });
    }
  }
  
  const handlerHide=(section)=>{
    setHideSection({...hideSection,
      section1:true,
      section2:true,
      section3:true,
      section4:true,
      section5:true,
    });
    setHideSection({...hideSection,[section]:!hideSection[section]})
  }

  return (
    <>
      <UserHeader names={datas.firstname+" "+datas.lastname}/>
      {/* Page content */}
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('treatment_progress') + " , " + t('wait_just_moment')}
      >
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="10">
                      <h3 className="mb-0">Informations</h3>
                    </Col>
                    <Col xs="2" className="align-items-center">
                      <Button color="outline-info" size="sm" onClick={()=>{handlerHide("section1")}}>{hideSection.section1?t("show"):t("hide")} </Button>
                    </Col>
                  </Row>
                </CardHeader>
                {!hideSection.section1?<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">{t("personal_information")}</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("firstname")} </label>
                            <p>{datas.firstname}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("lastname")} </label>
                            <p>{datas.lastname}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("address_mail")} </label>
                            <p>{datas.address_mail}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("phone")} </label>
                            <p>{datas.phone}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("place_residence")}
                            </label>
                            <p>{datas.place_residence}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("identity_card")}
                            </label>
                            <p>{datas.identity_card}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{"CNI "+t("expires_on")}</label>
                            <p>{datas.expires_on_idcard}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >Num Passport</label>
                            <p>{datas.num_passport}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{"PASSPORT "+t("expires_on")}</label>
                            <p>{datas.expires_on_passport}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">{t("family_information")}</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("father_name")}</label>
                            <p>{datas.father_name}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("father_contact")}</label>
                            <p>{datas.father_contact}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("mother_name")}</label>
                            <p>{datas.mother_name}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("mother_contact")}</label>
                            <p>{datas.mother_contact}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("name_guarantor")}</label>
                            <p>{datas.name_guarantor}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("guarantor_contact")}</label>
                            <p>{datas.guarantor_contact}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("guardian")}</label>
                            <p>{datas.guardian}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("guardian_contact")}</label>
                            <p>{datas.guardian_contact}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("profesions_parents")}</label>
                            <p>{datas.profesions_parents}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                      </Col>
                     </Row>
                  </Form>
                </CardBody>:null}
              </Card>
              <br/>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="10">
                      <h3 className="mb-0">{t("follow_up")}</h3>
                    </Col>
                    <Col xs="2" className="align-items-center">
                      <Button color="outline-info" size="sm" onClick={()=>{handlerHide("section2")}}>{hideSection.section2?t("show"):t("hide")} </Button>
                    </Col>
                  </Row>
                </CardHeader>
                {!hideSection.section2?<CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("type_follow_up")+" (Option)"}</label>
                            <p>{datas.myFollowUp.type+" ("+datas.myFollowUp.option+")"}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("target_countries")}</label>
                            <p>{datas.myFollowUp.detailType.target_countries}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      {datas.myFollowUp.type!="avi"?<Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("last_diploma_obtained")}</label>
                            <p>{datas.myFollowUp.detailType.last_diploma_obtained+"("+datas.myFollowUp.detailType.date_graduation+")"}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("targeted_training")}</label>
                            <p>{datas.myFollowUp.detailType.targeted_training}</p>
                          </FormGroup>
                        </Col>
                      </Row>:null}
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("amount_paid")}</label>
                            <p>{datas.myFollowUp.amount}</p>
                          </FormGroup>
                        </Col>
                        {datas.myFollowUp.type!="avi"?<Col lg="8">
                          <FormGroup>
                            <label className="form-control-label"
                            >{t("any_additional_information")}</label>
                            <p>{datas.myFollowUp.detailType.any_additional_information}</p>
                          </FormGroup>
                        </Col>:null}
                      </Row>
                    </div>

                    {currentUser.role!=="user"?
                      <div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">{t("progress_status_case")}</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label"
                                >Status</label>
                                <Input type="select"
                                  value={progressInfo.status}
                                  onChange={(val) => {setProgressInfo({...progressInfo,status:val.target.value}) }} >
                                  <option value={0}>{t("on_hold")}</option>
                                  <option value={1}>{t("in_progress")}</option>
                                  <option value={2}>{t("suspended")}</option>
                                  <option value={3}>{t("VISA_obtained")}</option>
                                  <option value={4}>{t("VISA_refused")}</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label"
                                >{t("progress")}</label>
                                <Input type="select"
                                  value={progressInfo.percent}
                                  onChange={(val) => {setProgressInfo({...progressInfo,percent:val.target.value}) }} >
                                  <option value={0}>{t("on_hold")}</option>
                                  {datas.myFollowUp.type!="avi"?<option value={10}>{t("profiling")}</option>:null}
                                  {datas.myFollowUp.type!="avi"?<option value={15}>{t("research_admission")}</option>:null}
                                  {datas.myFollowUp.type!="avi"?<option value={50}>{t("admission_obtained")}</option>:null}
                                  <option value={75}>{t("appointment_embassy")}</option>
                                  <option value={100}>{t("complete")}</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <Input
                                className="form-control-alternative"
                                placeholder={t("any_additional_information")}
                                rows="4"
                                value={progressInfo.note}
                                type="textarea"
                                onChange={(val)=>{setProgressInfo({...progressInfo,note:val.target.value})}} 
                              />
                            </Col>
                          </Row>
                          <br/>
                          <Row>
                            <Col sm="9"></Col>
                            <Col sm="3">
                              <Button outline color="primary" onClick={()=>{updateProgress()}}>{t("modify")}</Button>
                            </Col>
                          </Row>
                          </div>
                      </div>:null}
                  </Form>
                </CardBody>:null}
              </Card>
              <br/>
              {datas.myFollowUp.type!="avi"?<div><Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="10">
                        <h3 className="mb-0">{t("case")}s</h3>
                      </Col>
                      <Col xs="2" className="align-items-center">
                        <Button color="outline-info" size="sm" onClick={()=>{handlerHide("section3")}}>
                          {hideSection.section3?t("show"):t("hide")} </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!hideSection.section3?<CardBody>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">{t("case")}</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {itemsTable.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                          .map(doc => {
                            return (
                              <tr key={doc.title}>
                                <td>
                                  <a className="btn btn-light" href={Global.SERVER_URL + '/cases/'+datas._id+"/"+doc.title}>{doc.name}</a>
                                </td>
                                <td>{doc.date?moment(doc.date).format("MMM/YYYY"):"Non défini"}</td>
                              </tr>
                            );
                        })
                        }
                        </tbody>
                      </Table>
                      <Pagination>
                        {[...Array(Math.round(itemsTable.length/pageSize)+1)].map((page, i) => (
                          <PaginationItem active={i === currentPage} key={i}>
                            <PaginationLink onClick={e => handlePageClick(e, i)} href="#1">{i + 1}</PaginationLink>
                          </PaginationItem>
                        ))}
                      </Pagination>{currentUser.role!=="user"?<div className="pl-lg-4">
                            <Row>
                              <Col sm="9">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                  >{t("title_document")}</label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-last-name"
                                    placeholder={t("title_document")}
                                    type="text"
                                    value={file.name}
                                    onChange={(val) => { setFile({ ...file, "name": val.target.value }) }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="3">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    views={["year", "month"]}
                                    label={t("month_year")}
                                    helperText={t("publishing_date")}
                                    minDate={new Date("1970")}
                                    maxDate={new Date()}
                                    value={file.date}
                                    onChange={(val) => { setFile({ ...file, "date": val}) }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            
                              <Col lg="12">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <div className="dropzone-msg dz-message needsclick">
                                        <i className="fas fa-cloud-upload-alt" />
                                        <h5 className="dropzone-msg-title">{t("click_upload_doc")}</h5>
                                    </div>
                                </div>
                                  <br/>
                                {file.file!=null?<div>
                                  <Row >
                                    <Col sm="8">
                                        <p>{file.title}</p>
                                    </Col>
                                    <Col sm="2">
                                        <Button
                                          color="outline-danger"
                                          onClick={() =>{
                                              setFile({...file,
                                                title:"",
                                                date:new Date(),
                                                file:null
                                              });
                                          }}
                                        >{t("delete")}
                                        </Button>
                                    </Col>
                                  </Row>
                                  <br/>
                                </div>:null}
                              </Col>
                              <Col lg="12">
                                <br/>
                                <Row>
                                    <Col sm="2">
                                      <Button outline color="danger" onClick={()=>{
                                        setFile({...file,
                                          title:"",
                                          date:new Date(),
                                          file:null
                                        })}}>{t("cancel")}</Button>
                                    </Col>
                                    <Col sm="2"></Col>
                                    <Col sm="8">
                                      <Button outline color="primary" onClick={()=>{uploadFile()}}>{t("send")}</Button>
                                    </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>:null}
                  </CardBody>:null}
                </Card>
                <br/>
              </div>:null}
              {datas.myFollowUp.type!="avi"?<div><Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="10">
                        <h3 className="mb-0">{t("school_information")}</h3>
                      </Col>
                      <Col xs="2" className="align-items-center">
                        <Button color="outline-info" size="sm" onClick={()=>{handlerHide("section4")}}>
                          {hideSection.section4?t("show"):t("hide")} </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {!hideSection.section4?<CardBody>
                    <Form>
                      <div>
                        <div>
                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">{t("school_name")}</th>
                                <th scope="col">{t("username")}</th>
                                <th scope="col">{t("password")}</th>
                                {currentUser.role!=="user"?<th scope="col"></th>:null}
                              </tr>
                            </thead>
                            <tbody>

                            
                            {schoolsTable.slice(currentSchoolPage * pageSchoolSize, (currentSchoolPage + 1) * pageSchoolSize)
                              .map(doc => {
                                if (doc.files.length!=0) {
                                  return (
                                    <tr key={doc.id}  onClick={()=>rowSchoolClick(doc)}>
                                      <td >
                                      <a className="btn btn-light" rel="noreferrer" target="_blank" href={Global.SERVER_URL +'/cases/'+datas._id+'/followUp/'+schoolSection.follow_up_id+"/"+doc.file}>{doc.title+" ("+doc.file+")"}</a>
                                      </td>
                                      <td>{doc.username}</td>
                                      <td>{doc.password}</td>
                                      {currentUser.role!=="user"?
                                        <td><Button color="outline-danger" size="sm" onClick={()=>{deleteFile(doc.file,doc.title)}}>{t("delete")}</Button></td>
                                        :null
                                      }
                                    </tr>
                                  );
                                }

                                return (
                                  <tr key={doc.id}  onClick={()=>rowSchoolClick(doc)}>
                                    <td >
                                        <a className="btn btn-light" rel="noreferrer" >{doc.title}</a>
                                    </td>
                                    <td>{doc.username}</td>
                                    <td>{doc.password}</td>
                                    {currentUser.role!=="user"?
                                      <td><Button color="outline-danger" size="sm" onClick={()=>{deleteFile(doc.file,doc.title)}}>{t("delete")}</Button></td>
                                      :null
                                    }
                                  </tr>
                                );
                            })
                            }
                            </tbody>
                          </Table>
                          <Pagination>
                            {[...Array(Math.round(schoolsTable.length/pageSchoolSize)+1)].map((page, i) => (
                              <PaginationItem active={i === currentSchoolPage} key={i}>
                                <PaginationLink onClick={e => handleShoolPageClick(e, i)} href="#2">{i + 1}</PaginationLink>
                              </PaginationItem>
                            ))}
                          </Pagination>
                        </div>

                        {currentUser.role!=="user"?<div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label"
                                >{t("school_name")} </label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t("school_name")}
                                  value={schoolSection.title}
                                  onChange={(val)=>{setSchoolSection({...schoolSection,title:val.target.value})}} 
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">URL</label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t("url_web_site")}
                                  value={schoolSection.url}
                                  onChange={(val)=>{setSchoolSection({...schoolSection,url:val.target.value})}} 
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label  className="form-control-label"
                                >{t("username")}</label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t("username")}
                                  value={schoolSection.username}
                                  onChange={(val)=>{setSchoolSection({...schoolSection,username:val.target.value})}} 
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label  className="form-control-label">{t("password")}</label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t("password")}
                                  value={schoolSection.password}
                                  onChange={(val)=>{setSchoolSection({...schoolSection,password:val.target.value})}} 
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                  <label className="form-control-label"
                                  >Status</label>
                                  <Input type="select"
                                    value={schoolSection.state}
                                    onChange={(val) => {setSchoolSection({...schoolSection,state:val.target.value}) }} >
                                    <option value={0}>{t("in_progress")}</option>
                                    <option value={1}>{t("submitted")}</option>
                                    <option value={2}>{t("eligibility")}</option>
                                    <option value={3}>{t("rejected")}</option>
                                  </Input>
                                </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <label  className="form-control-label">{t("any_additional_information")}</label>
                              <FormGroup>
                                <Input
                                  className="form-control-alternative"
                                  placeholder={t("any_additional_information")}
                                  rows="4"
                                  value={schoolSection.any_additional_information}
                                  type="textarea"
                                  onChange={(val)=>{setSchoolSection({...schoolSection,any_additional_information:val.target.value})}} 
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Col lg="12">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <div className="dropzone-msg dz-message needsclick">
                                    <i className="fas fa-cloud-upload-alt" />
                                    <h5 className="dropzone-msg-title">{t("click_upload")}</h5>
                                </div>
                            </div>
                            <br/>
                            <div>
                                {listFiles}
                            </div>
                          </Col>
                          <br/>
                          <Row>
                              <Col sm="6"></Col>
                              <Col sm="3">
                                <Button outline color="danger" onClick={()=>{
                                  setSchoolSection({...schoolSection,
                                    school_id:-1,
                                    title:"",
                                    url:"",
                                    state:-1,
                                    username:"",
                                    password:"",
                                    any_additional_information:""
                                  })}}>{t("cancel")}</Button>
                              </Col>
                              <Col sm="3">
                                <Button outline color="primary" onClick={()=>{addSchool()}}>{schoolSection.school_id==-1?t("add"):t("modify")}</Button>
                              </Col>
                          </Row>
                        </div>:null}
                      </div>
                    </Form>
                  </CardBody>:null}
                </Card>
                <br/>
              </div>:null}
              {datas.myFollowUp.type=="avi"?<div><Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="10">
                      <h3 className="mb-0">{t("seconded_services")}</h3>
                    </Col>
                    <Col xs="2" className="align-items-center">
                      <Button color="outline-info" size="sm" onClick={()=>{handlerHide("section5")}}>
                        {hideSection.section5?t("show"):t("hide")} </Button>
                    </Col>
                  </Row>
                </CardHeader>
                {!hideSection.section5?<CardBody>
                  <SecondedServices 
                    datas={datas} 
                    token={currentUser.token} />
                </CardBody>:null}
              </Card></div>:null}
            </Col>

            {datas.hasOwnProperty("role")?<SidebarProfile 
              myInfos={datas} 
              myStat={myStat}
            />:null}
          
          </Row>
          <NotificationContainer />
        </Container>
      </LoadingOverlay>
    </>
  );
};
export default withTranslation()(CandidateFollowUp);
