/*!
=========================================================
* YimmiDashboard React - v1.2.0
=========================================================
* Coded by Creative Tim
=========================================================
*/
// core components
import Header from "components/Headers/Header.js";
import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from 'react-loading-overlay';
import {useDropzone} from 'react-dropzone';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useHistory } from 'react-router-dom';
import Avatar from 'react-string-avatar';
import * as R from 'ramda';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// reactstrap components
import {
  Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form,
  FormGroup, Input, Media, Modal, ModalBody, ModalFooter, Progress, Row, UncontrolledDropdown
} from "reactstrap";
import Global from '../../constants/Global';

const Clients = (props) => {
  const { t } = props;
  const SweetAlert = withReactContent(Swal)
  const [modal, setModal] = useState(false);
  const [modalFollowUp, setModalFollowUp] = useState(false);
  const [typeFollowUp, setTypeFollowUp] = useState("study");
  const [optionFollowUp, setOptionFollowUp] = useState("Basic");
  const [curID, setCurID] = useState("");
  const [currentUser, setCurrentUser] = useState({role:"user"})
  const [action_followUp, setActionFollowUp] = useState("create");
  const [listAmbassador, setListAmbassador] = useState([]);

  const toggle = () => setModal(!modal);
  const toggleFollowUp = () => setModalFollowUp(!modalFollowUp);
  let history = useHistory();

  const [infosCurUser, setInfosCurUser] = useState(
    {
      firstname: "",
      lastname: "",
      address_mail: "",
      phone: "",
      identity_card: "",
      num_passport: "",
      expires_on_passport: "",
      expires_on_idcard: "",
      place_residence: "",
      token: "",
      role: "user",
      myFollowUp:[],
      father_name: "", father_contact: "",
      mother_name: "", other_contact: "",
      guardian: "", guardian_contact: "",
      name_guarantor: "", guarantor_contact: "",
      profesions_parents: ""
    }
  );
  const [infosFollowUpStudy, setInfosFollowUpStudy] = useState(
    {
      "last_diploma_obtained": "baccalaureate",
      "date_graduation": "",
      "target_countries": "France",
      "targeted_training": "",
      "any_additional_information": ""
    }
  );
  const [infosFollowUpCare, setInfosFollowUpCare] = useState(
    {
      "place_residence": "",
      "current_training": "",
      "expectations_follow_up": ""
    }
  ); 
  const [infosFollowUpVisit, setInfosFollowUpVisit] = useState(
    {
      "reason_travel": "medical",
      "destination": "",
      "any_additional_information": ""
    }
  );

  const [candidatesList, setCandidateList] = useState([]);
  const [ambassadorList, setAmbassadorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const [filesAvi, setFilesAvi] = useState([]);
  var filesAviTransit=[]


  const columns = [{
    dataField: 'names',
    text: t("firstname") + '/' + t("lastname"),
    filter: textFilter({
      placeholder: t("search")+" ...",  
    }),
    formatter: (rowContent, row) => {
      return (<Media className="align-items-center">
        <Avatar 
          string={row.names.split(" ")[0]}
          roundShape="true" 
          width={34}
          wrapperStyle={{ border: '4px solid red' }} autoColor={true} />
        <span className="mb-0 text-sm">  {" "+row.names} </span>
      </Media>
      )
    }
  },
  {
    dataField: 'amount',
    text: t("amount_paid"),
    sort: true,
    headerStyle:  { 'white-space' : 'nowrap' }
  },
  {
    dataField: 'progress',
    text: t("progress"),
    sort: true,
    formatter: (rowContent, row) => {
      return (<div className="d-flex align-items-center">
        <span className="mr-2">{rowContent.percent}%</span>
        <div>
          <Progress
            max="100"
            value={rowContent.percent}
            barClassName={"bg-gradient-" + Global.percent(rowContent.percent)}
          />
        </div>
      </div>)
    }
  },
  currentUser.role!=="user"?{
    dataField: '_id',
    text: '',
    formatter: (rowContent, row) => {
      
      return (<UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={(e) => {
            setTypeFollowUp("study")
            setOptionFollowUp("Basic")
            setActionFollowUp("create")
            setCurID(row._id)
            setInfosFollowUpStudy({
              "last_diploma_obtained": "baccalaureate",
              "date_graduation": "",
              "target_countries": "France",
              "targeted_training": "",
              "any_additional_information": ""
            })
            setInfosFollowUpCare({
              "place_residence": "",
              "current_training": "",
              "expectations_follow_up": ""
            })
            setInfosFollowUpVisit({
              "reason_travel": "medical",
              "destination": "",
              "any_additional_information": ""
            })

            var _listAmbassador=[] 
            ambassadorList.forEach(element => {if(element._id===currentUser._id) _listAmbassador.push(element._id)});
            setListAmbassador(_listAmbassador)
            
            toggleFollowUp()
          }}>
              {t("add_follow_up")}
          </DropdownItem>
          <DropdownItem onClick={(e) => {
            toggle()
            row.token=token
            setInfosCurUser(row)
          }}>
              {t("edit_information")}
          </DropdownItem>
          <DropdownItem onClick={(e) => {
              deleteUser(row._id)
              e.preventDefault()
            }}>
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      )
    }
  }:{}
  ];

  const columnsExpand=[{
      dataField: 'type',
      text: t("type_follow_up"),
      formatter: (rowContent, row) => {
        return (<p>{rowContent+" ("+row.option+")"}</p>
        )
      }
    }, {
      dataField: 'amount',
      text: t("amount_paid"),
      sort: true,
      headerStyle:  { 'white-space' : 'nowrap' }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (rowContent, row) => {
        if (row.status === 0) return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />{t("on_hold")}</Badge>)
        else if (row.status === 1) return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-info" />{t("in_progress")}</Badge>)
        else if (row.status === 2) return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-danger" />{t("suspended")}</Badge>)
        else if (row.status === 3) return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-success" />{t("VISA_obtained")}</Badge> )
        else if (row.status === 4) return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-danger" />{t("VISA_refused")}</Badge> )
      }
    },
    {
      dataField: 'progress',
      text: t("progress"),
      sort: true,
      formatter: (rowContent, row) => {
        return (<div className="d-flex align-items-center">
          <span className="mr-2">{row.percent}%</span>
          <div>
            <Progress
              max="100"
              value={row.percent}
              barClassName={"bg-" + Global.percent(row.percent)}
            />
          </div>
        </div>)
      }
    },
    {
      dataField: '_id',
      text: '',
      formatter: (rowContent, row) => {
        return (<UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {currentUser.role!=="user"?<DropdownItem onClick={(e) => {
                editFollowUpInfos(row)
              }}>
                {t("update")}
              </DropdownItem>:null
            }
            <DropdownItem href="#" onClick={(e) => {
              history.push({
                pathname: '/Admin/candidate-follow-up',
                state: {user_id:row.user_id,follow_up_id:row._id}
              });
            }}>
              {t("follow_up")}
            </DropdownItem>
            <DropdownItem className='bg-gradient-danger' onClick={(e) => {
                deleteFollow(row.user_id,row._id)
                e.preventDefault()
              }}>
              {t("delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        )
      }
    }
  ];
  const expandRow = {
    renderer: row => (
      <BootstrapTable
        headerWrapperClasses="thead-light"
        wrapperClasses="align-items-center table-flush"
        keyField="_id"
        data={row.myFollowUp?row.myFollowUp:[]}
        columns={columnsExpand}
      />
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands)return <b>-</b>;
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) return (<b>-</b>);
      return <b>+</b>;
    }
  };

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop :acceptedFiles => {
      filesAviTransit=filesAvi
      acceptedFiles.map((itm)=>{filesAviTransit.push(itm)})
      setFilesAvi(filesAviTransit)
    }
  });

  useEffect(() => {
    document.title = t("candidate")+"s :: YimmiLine"
    var found = false
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setToken(JSON.parse(localStorage[key]).token)
        setCurrentUser(JSON.parse(localStorage[key]))
        setInfosCurUser({ ...infosCurUser, "token":JSON.parse(localStorage[key]).token})
        found = true

        setIsLoading(true)
        fetch(Global.SERVER_URL + '/myListUsers', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({"token":JSON.parse(localStorage[key]).token})
        }).then(response => response.json())
          .then(responseData => {
            if(responseData.error===200) setCandidateList(responseData.list)
            else NotificationManager.error(t('unknown_error'), 'Message');
            setIsLoading(false)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }
          });


        fetch(Global.SERVER_URL + '/listAmbassador', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({token:JSON.parse(localStorage[key]).token})
        }).then(response => response.json())
          .then(responseData => {
            setAmbassadorList(responseData)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }
          });
      }
    if (!found) history.push('/auth/login');
  }, []);

  const handleSubmit = () => {
    setIsLoading(true)
    setModal(false)

    fetch(Global.SERVER_URL + '/addAmbassador', {
      method: 'post',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(infosCurUser)
    }).then(response => response.json())
      .then(responseData => {
        setModal(true)
        if (responseData.hasOwnProperty("error")) {
          if (responseData.error === 200) {
            setModal(false)
            NotificationManager.success(t('successful_operation'), 'Message');
            window.location.reload()
          }
          else if (responseData.error === 405) NotificationManager.warning(t("candidate_already_email"), 'Message');
          else NotificationManager.error(t('unknown_error'), 'Message');
        } else NotificationManager.error(t('unknown_error'), 'Message');

        setIsLoading(false)
      }, (error) => {
        if (error) {
          NotificationManager.error(t('unknown_error'), 'Message');
          setIsLoading(false)
        }
      });
  }
  const handleSubmitFollowUp=()=>{
    var followUpInfos={}
    followUpInfos.type=typeFollowUp
    followUpInfos.listAmbassador=listAmbassador
    followUpInfos.option=optionFollowUp
    followUpInfos.curID=curID
    followUpInfos.action_followUp=action_followUp
    
    followUpInfos.token=token
    followUpInfos.lng=t('next')
    followUpInfos.amount=0
    followUpInfos.status=0
    followUpInfos.percent=0
    
    var msgError=""
    if (typeFollowUp==='study'){
      followUpInfos.detailType=infosFollowUpStudy
      if(infosFollowUpStudy.date_graduation==='') msgError=t('date_graduation');
      else if(infosFollowUpStudy.target_countries.length===0) msgError=t('target_countries');
      else if(infosFollowUpStudy.targeted_training==='') msgError=t('targeted_training');
    }
    else if (typeFollowUp==='visit'){ 
      followUpInfos.detailType=infosFollowUpVisit
      if(infosFollowUpVisit.destination==='') msgError=t('destination');
    }
    else if (typeFollowUp==='care'){
      followUpInfos.detailType=infosFollowUpCare
      if(infosFollowUpCare.place_residence==='') msgError=t('place_residence');
      else if(infosFollowUpCare.current_training==='') msgError=t('current_training');
    }
    else if (typeFollowUp==='avi'){
      followUpInfos.detailType={target_countries:infosFollowUpStudy.target_countries}
      if(infosFollowUpStudy.target_countries.length===0) msgError=t('target_countries');
    }
    else if (listAmbassador.length===0)msgError=t('ambassador')

    if(msgError!=="")NotificationManager.error(msgError, 'empty_field');
    else{
      var _listAmbassador=[]
      listAmbassador.forEach(element => {
        ambassadorList.forEach(amba => {
          if(amba._id===element){
            _listAmbassador.push({
              id:amba._id,
              address_mail:amba.address_mail,
              names:amba.firstname+" "+amba.lastname,

            })
          }
        });
        
      });
      followUpInfos.listAmbassador=_listAmbassador
      
      setIsLoading(true)
      setModalFollowUp(false)
      if (typeFollowUp==='avi'){
        var data;
        for (let i = 0; i < filesAvi.length; i++) {
          if(filesAvi[i].path){
            data = new FormData();
            data.append('file',filesAvi[i]);
            data.append('type',"avi");
            data.append('candidate_id', curID);
            data.append('token',token);
            
            fetch(Global.SERVER_URL + '/addFile', {
                method: 'POST',
                body: data
            }).then(response => response.json())
            .then(responseData => {
              if (responseData.hasOwnProperty("error")){
                if (responseData.error === 200){
                }else NotificationManager.error(responseData.msg, 'Message');
              }
              setIsLoading(false)
            }, (error) => {
              if (error) {
                NotificationManager.error(t('unknown_error')+" :: ", 'Message');
                setIsLoading(false)
              }
            });
          }
        }
      }

      fetch(Global.SERVER_URL + '/setFollowUp', {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(followUpInfos)
      }).then(response => response.json())
        .then(responseData => {
          if (responseData.hasOwnProperty("error")) {
            if (responseData.error === 200) {
              NotificationManager.success(t('successful_operation'), 'Message');
              window.location.reload()
            }
            else {setModalFollowUp(true);NotificationManager.error(t('unknown_error'), 'Message');}
          } else {setModalFollowUp(true);NotificationManager.error(t('unknown_error'), 'Message');}
  
          setIsLoading(false)
        }, (error) => {
          if (error) {
            setModalFollowUp(true);
            NotificationManager.error(t('unknown_error')+" 4", 'Message');
            setIsLoading(false)
          }
        });
    }
  }
  const editFollowUpInfos = (data) => {
    setCurID(data.user_id)
    setTypeFollowUp(data.type)
    setOptionFollowUp(data.option)
    setActionFollowUp(data._id)

    var _listAmbassador=[] 
    data.listAmbassador.forEach(element => {_listAmbassador.push(element.id)});
    setListAmbassador(_listAmbassador)

    if(data.type==="study"){
      setInfosFollowUpStudy({...infosFollowUpStudy,
        "last_diploma_obtained": data.detailType.last_diploma_obtained,
        "date_graduation": data.detailType.date_graduation,
        "target_countries": data.detailType.target_countries,
        "targeted_training": data.detailType.targeted_training,
        "any_additional_information": data.detailType.any_additional_information
      })
    }
    else if(data.type==="visit"){
      setInfosFollowUpVisit({...infosFollowUpVisit,"destination": data.detailType.destination})
    }
    else if(data.type==="care"){
      setInfosFollowUpCare({...infosFollowUpCare,
        "place_residence": data.detailType.place_residence,
        "current_training": data.detailType.current_training
      })
    }
    else if(data.type==="avi"){
      setInfosFollowUpStudy({...infosFollowUpStudy,
        "target_countries": data.detailType.target_countries
      })
      
      if(data.filesAvi){
        filesAviTransit=[];
        data.filesAvi.forEach(element => {
          if(element.type==="avi")filesAviTransit.push(element)
        });
        setFilesAvi(filesAviTransit)
      }
    }
    setModalFollowUp(true)
  }
  const deleteUser = (user_id) => {
    SweetAlert.fire({
      title: t("deleting"),
      text: t("confirm_delete"),
      icon: 'info',
      confirmButtonText: t("i_confirm"),
      cancelButtonText: t("no"),
      showCancelButton: true,
    }).then((result) => {
        if(result.isDismissed){}
        else if(result.isConfirmed){
          setIsLoading(true)
          fetch(Global.SERVER_URL + '/deleteUser', {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({token,user_id})
          }).then(response => response.json())
            .then(responseData => {
              if(responseData.hasOwnProperty("error"))
                if(responseData.error===200){
                  NotificationManager.success(t('successful_operation'), 'Message');
                  window.location.reload()
                }else NotificationManager.error(responseData.msg, 'Message');
              else NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }, (error) => {
              if (error) {
                NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
              }
            });
        }
    })
  }
  const deleteFollow = (user_id,follow_up_id) => {
      SweetAlert.fire({
        title: t("deleting"),
        text: t("confirm_delete"),
        icon: 'info',
        confirmButtonText: t("i_confirm"),
        cancelButtonText: t("no"),
        showCancelButton: true,
      }).then((result) => {
          if(result.isDismissed){}
          else if(result.isConfirmed){
            setIsLoading(true)
            fetch(Global.SERVER_URL + '/deleteFollow', {
              method: 'post',
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
              },
              body: JSON.stringify({token,user_id,follow_up_id})
            }).then(response => response.json())
              .then(responseData => {
                if(responseData.hasOwnProperty("error"))
                  if(responseData.error===200){
                    NotificationManager.success(t('successful_operation'), 'Message');
                    window.location.reload()
                  }else NotificationManager.error(responseData.msg, 'Message');
                else NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
              }, (error) => {
                if (error) {
                  NotificationManager.error(t('unknown_error'), 'Message');
                  setIsLoading(false)
                }
              });
          }
      })
    }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <LoadingOverlay
          active={isLoading}
          spinner
          text={t('treatment_progress') + " , " + t('wait_just_moment')}
        >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <CardHeader className="border-0">
                  <Row>
                    <Col className="mb-5 mb-xl-0" xl="10">
                      <h3 className="mb-0">{t("candidate")}s</h3>
                    </Col>
                    <Col xl="2">
                        {currentUser.rol!=="user"?<Button outline color="primary" onClick={()=>{
                          toggle()
                          setInfosCurUser({
                              firstname: "",
                              lastname: "",
                              address_mail: "",
                              phone: "",
                              identity_card: "",
                              num_passport: "",
                              expires_on_passport: "",
                              expires_on_idcard: "",
                              place_residence: "",
                              token,
                              role: "user",
                              myFollowUp:[],
                              father_name: "", father_contact: "",
                              mother_name: "", other_contact: "",
                              guardian: "", guardian_contact: "",
                              name_guarantor: "", guarantor_contact: "",
                              profesions_parents: ""
                            })
                          }}>{t("add")}</Button>:null}
                    </Col>
                  </Row>
                </CardHeader>

                <div>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        headerWrapperClasses="thead-light"
                        wrapperClasses="align-items-center table-flush"
                        keyField="_id"
                        data={candidatesList}
                        columns={columns}
                        expandRow={ expandRow }
                        filter={ filterFactory() }
                        pagination={paginationFactory({ 
                          sizePerPage: 7,
                          hideSizePerPage:true,
                        })}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </Row>
          
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalBody>
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">{t("candidate_information")}</h6>
                    <div>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("firstname")}*</label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder={t("your_firstname")}
                              type="text"
                              value={infosCurUser.firstname}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "firstname": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lastname"
                            >{t("lastname")}*</label>
                            <Input
                              className="form-control-alternative"
                              id="input-lastname"
                              placeholder={t("your_lastname")}
                              type="text"
                              value={infosCurUser.lastname}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "lastname": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >{t("address_mail")}*</label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder={t("your") + " " + t("address_mail")}
                              type="email"
                              value={infosCurUser.address_mail}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "address_mail": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >{t("phone")}*</label>
                            <Input
                              className="form-control-alternative"
                              id="input-phone"
                              placeholder={t("your_phone")}
                              type="phone"
                              value={infosCurUser.phone}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "phone": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{"N° CNI"}*</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={"N° CNI"}
                              type="text"
                              value={infosCurUser.identity_card}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "identity_card": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("expires_on")}*</label>
                            <Input
                              className="form-control-alternative"
                              placeholder="MM/YY"
                              type="text"
                              value={infosCurUser.expires_on_idcard}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "expires_on_idcard": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{"Num Passport"}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={"Num Passport"}
                              type="text"
                              value={infosCurUser.num_passport}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "num_passport": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("expires_on")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder="MM/YY"
                              type="text"
                              value={infosCurUser.expires_on_passport}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "expires_on_passport": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >{t("place_residence")}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder={t("place_residence")}
                              type="text"
                              value={infosCurUser.place_residence}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "place_residence": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">{t("family")} </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("father_name")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("father_name")}
                              type="text"
                              value={infosCurUser.father_name}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "father_name": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("phone_") + " / Email"}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("father_contact")}
                              type="text"
                              value={infosCurUser.father_contact}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "father_contact": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("mother_name")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("mother_name")}
                              type="text"
                              value={infosCurUser.mother_name}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "mother_name": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("phone_") + " / Email"}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("mother_contact")}
                              type="text"
                              value={infosCurUser.mother_contact}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "mother_contact": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("guardian")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("guardian")}
                              type="text"
                              value={infosCurUser.guardian}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "guardian": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("phone_") + " / Email"}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("guardian_contact")}
                              type="text"
                              value={infosCurUser.guardian_contact}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "guardian_contact": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("name_guarantor")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("name_guarantor")}
                              type="text"
                              value={infosCurUser.name_guarantor}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "name_guarantor": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("phone_") + " / Email"}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("guarantor_contact")}
                              type="text"
                              value={infosCurUser.guarantor_contact}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "guarantor_contact": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("profesions_parents")}</label>
                            <Input
                              className="form-control-alternative"
                              placeholder={t("profesions_parents")}
                              type="text"
                              value={infosCurUser.profesions_parents}
                              onChange={(val) => { setInfosCurUser({ ...infosCurUser, "profesions_parents": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleSubmit()}>{t("validate")}</Button>{' '}
              <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalFollowUp} toggle={toggleFollowUp} >
            <ModalBody>
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form role="form" onSubmit={handleSubmitFollowUp}>
                    <h6 className="heading-small text-muted mb-4">{action_followUp==="create"?t("add_follow_up"):'Modification'}</h6>
                    <div>
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("type_follow_up")}
                            </label>
                            <Input type="select"
                              value={typeFollowUp}
                              onChange={(val) => {
                                setTypeFollowUp(val.target.value) 
                                if(val.target.value=="study")setOptionFollowUp("Basic")
                                else if(val.target.value=="visit")setOptionFollowUp("1")
                                else if(val.target.value=="care")setOptionFollowUp("1")
                                else if(val.target.value=="avi")setOptionFollowUp("Slim")
                                }} >
                              <option value={"study"}>YimmiLine Study</option>
                              <option value={"visit"}>YimmiLine Visit</option>
                              <option value={"care"}>YimmiLine Care</option>
                              <option value={"avi"}>YimmiLine AVI</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >Option</label>
                            <Input type="select"
                              value={optionFollowUp}
                              onChange={(val) => { setOptionFollowUp(val.target.value) }} >
                                {typeFollowUp === "study" ? <option value={"Basic"}>Basic</option> : null}
                                {typeFollowUp === "avi" ?<option value={"Slim"}>Slim</option>:<option value={"1"}>1</option>}
                                {typeFollowUp === "avi" ? <option value={"Slim+AS"}>Slim+AS</option> : null}
                                {typeFollowUp === "care" || typeFollowUp === "study" ? <option value={"2"}>2</option> : null}
                                {typeFollowUp === "study" ? <option value={"3"}>3</option> : null}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      {typeFollowUp === "study" ? <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >{t("last_diploma_obtained")}</label>
                              <Input type="select"
                                value={infosFollowUpStudy.last_diploma_obtained}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "last_diploma_obtained": val.target.value }) }} >
                                <option value={"baccalaureate"}>{t("baccalaureate")}</option>
                                <option value={"BTS"}>BTS</option>
                                <option value={"Licence"}>Licence</option>
                                <option value={"Master"}>Master</option>
                                <option value={"doctorate"}>{t("doctorate")}</option>
                                <option value={"other"}>{t("other")}</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-date_graduation"
                              >{t("date_graduation")}*</label>
                              <Input
                                className="form-control-alternative"
                                id="input-date_graduation"
                                placeholder={t("date_graduation")}
                                type="text"
                                value={infosFollowUpStudy.date_graduation}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "date_graduation": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >{t("target_countries")}</label>
                              <Input type="select"
                                value={infosFollowUpStudy.target_countries}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "target_countries": val.target.value }) }} >
                                {Global.listCountries.map((country)=>{
                                  return (<option value={country}>{country}</option>)
                                })}
                              </Input>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                  <span className="text-nowrap">{t("CTRL_click")}</span>
                              </p>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-targeted_training"
                              >{t("targeted_training")}*</label>
                              <Input
                                className="form-control-alternative"
                                id="input-targeted_training"
                                placeholder={t("targeted_training")}
                                type="text"
                                value={infosFollowUpStudy.targeted_training}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "targeted_training": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >{t("any_additional_information")}
                              </label>
                              <Input
                                id="input-address"
                                className="form-control-alternative"
                                placeholder={t("any_additional_information")}
                                type="textarea"
                                value={infosFollowUpStudy.any_additional_information}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "any_additional_information": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> : null}
                      {typeFollowUp === "care" ? <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("place_residence")}*</label>
                              <Input
                                className="form-control-alternative"
                                placeholder={t("place_residence")}
                                type="text"
                                value={infosFollowUpCare.place_residence}
                                onChange={(val) => { setInfosFollowUpCare({ ...infosFollowUpCare, "place_residence": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-current_training"
                              >{t("current_training")}*</label>
                              <Input
                                id="input-current_training"
                                className="form-control-alternative"
                                placeholder={t("current_training")}
                                type="text"
                                value={infosFollowUpCare.current_training}
                                onChange={(val) => { setInfosFollowUpCare({ ...infosFollowUpCare, "current_training": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("expectations_follow_up")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder={t("expectations_follow_up")}
                                type="textarea"
                                value={infosFollowUpCare.expectations_follow_up}
                                onChange={(val) => { setInfosFollowUpCare({ ...infosFollowUpCare, "expectations_follow_up": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> : null}
                      {typeFollowUp === "visit" ? <div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >Destination *</label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Destination"
                                type="text"
                                value={infosFollowUpVisit.destination}
                                onChange={(val) => { setInfosFollowUpVisit({ ...infosFollowUpVisit, "destination": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("reason_travel")}*</label>

                              <Input type="select"
                                value={infosFollowUpVisit.reason_travel}
                                onChange={(val) => { setInfosFollowUpVisit({ ...infosFollowUpVisit, "reason_travel": val.target.value }) }} >
                                <option value={"medical"}>{t("medical")}</option>
                                <option value={"tourism"}>{t("tourism")}</option>
                                <option value={"professional"}>{t("professional")}</option>
                                <option value={"other"}>{t("other")}</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("any_additional_information")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder={t("any_additional_information")}
                                type="textarea"
                                value={infosFollowUpVisit.any_additional_information}
                                onChange={(val) => { setInfosFollowUpVisit({ ...infosFollowUpVisit, "any_additional_information": val.target.value }) }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> : null}
                      {typeFollowUp === "avi" ? <div>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >{t("target_countries")}</label>
                              <Input type="select"
                                value={infosFollowUpStudy.target_countries}
                                onChange={(val) => { setInfosFollowUpStudy({ ...infosFollowUpStudy, "target_countries": val.target.value }) }} >
                                <option value={"France"}>France</option>
                                <option value={"Deutsch"}>Allemagne</option>
                                <option value={"other"}>{t("other")}</option>
                              </Input>
                            </FormGroup>
                            <br/>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className="dropzone-msg dz-message needsclick">
                                    <i className="fas fa-cloud-upload-alt" />
                                    <p className="dropzone-msg-title">{t("click_upload_avi")}</p>
                                </div>
                            </div>
                            <br/>
                            {filesAvi.length>0?<aside>
                                <h4>Documents</h4>
                                {filesAvi.map(file => (
                                  <Row key={file.path?file.path:file.title} center>
                                    <Col lg="9"><h5 row><a target="_blank" href={""+Global.SERVER_URL+"/cases/"+curID+"/"+(file.hasOwnProperty("path")?file.path:file.title)}>{file.hasOwnProperty("path")?file.path:file.title}</a>  {file.path?"- "+Number(file.size/1024).toFixed(2)+" Kb":""} </h5></Col>
                                    <Col lg="2">
                                      <Button color="danger" outline size="sm" onClick={()=>{
                                        if(file.path)setFilesAvi(R.filter(o => o.path !== file.path, filesAvi))
                                        else setFilesAvi(R.filter(o => o.title !== file.title, filesAvi))

                                        fetch(Global.SERVER_URL + '/deleteFile', {
                                          method: 'POST',
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                            'Access-Control-Allow-Origin': '*'
                                          },
                                          body: JSON.stringify({token,candidate_id:curID,file:file.path?file.path:file.title})
                                        }).then(response => response.json())
                                        .then(responseData => {
                                          if (responseData.hasOwnProperty("error")){
                                            if (responseData.error === 200){
                                            }else NotificationManager.error(responseData.msg, 'Message');
                                          }
                                        }, (error) => {if (error)  NotificationManager.error(t('unknown_error')+" :: ", 'Message');});

                                      }}>{t("delete")}</Button>
                                    </Col>
                                  </Row>
                                ))}
                            </aside>:null}
                          </Col>
                        </Row>
                      </div> : null}

                      <FormGroup>
                        <label className="form-control-label">{currentUser.role +" "+t("ambassador")}s</label>
                        <Input type="select" multiple 
                          disabled={currentUser.role==="ambassador" || currentUser.role==="partner"?true:false}
                          value={listAmbassador}
                          onChange={(val) => { 
                            let value = Array.from(val.target.selectedOptions, option => option.value);
                            setListAmbassador(value) }} >
                              {ambassadorList.map((element, i) => {return  <option key={i} value={element._id}>{element.firstname+" "+element.lastname}</option>})}
                        </Input>
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => { handleSubmitFollowUp()}}>{action_followUp==="create"?t("add"):t("modify")}</Button>{' '}
              <Button color="secondary" onClick={toggleFollowUp}>{t("cancel")}</Button>
            </ModalFooter>
          </Modal>

          <NotificationContainer />
        </LoadingOverlay>
      </Container>
    </>
  );
};

export default withTranslation()(Clients);
