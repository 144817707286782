/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from 'react-string-avatar';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const AdminNavbar = (props) => {
  const {t} = props;
  let history = useHistory();
  const [myInfos, setMyInfos] = useState({firstname:" "})
  
  useEffect(() => {
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setMyInfos(JSON.parse(localStorage[key]))
      }
  }, []);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                 <Avatar string={myInfos.firstname} roundShape="true" wrapperStyle={{ border: '4px solid red' }} autoColor={true} />
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {myInfos.firstname}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{myInfos.role}</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>{t("my_profile")}</span>
                </DropdownItem>
                {myInfos.role==="user"?<DropdownItem to="/admin/my-files" tag={Link}>
                  <i className="ni ni-archive-2" />
                  <span>{t("my_cases")}</span>
                </DropdownItem>:null}
                <DropdownItem  target="_blank" to={{pathname:"https://wa.me/237653766754?text=J'ai un souci avec la plateforme My YimmiLine"}} tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>{t("a_question")}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="" onClick={(e) => {
                        localStorage.clear();
                        history.push('/auth/login');
                  }}>
                  <i className="ni ni-user-run" />
                  <span>{t("logout")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
export default withTranslation()(AdminNavbar);
