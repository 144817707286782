/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Board from "views/Index.js";
import Index from "views/examples/Login.js";
import Register from "views/examples/Register.js";
import Clients from "views/examples/Clients.js";
import Ambassadeurs from "views/examples/Ambassadeurs.js";
import Partners from "views/examples/Partners.js";
import Finances from "views/examples/Finances.js";
import Icons from "views/examples/Icons.js";


import CandidateFollowUp from "views/examples/CandidateFollowUp.js";
import Profile from "views/examples/Profile.js";
import MyFiles from "views/examples/MyFiles.js";

import i18n from './assets/locales/i18n';

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Index,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/index",
    name: i18n.t("dashboard"),
    icon: "ni ni-tv-2 text-primary",
    component: Board,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/candidate-follow-up",
    name: "",
    icon: "ni ni-single-02 text-yellow",
    component: CandidateFollowUp,
    layout: "/admin",
  },
  {
    path: "/my-files",
    name: "",
    icon: "ni ni-single-02 text-yellow",
    component: MyFiles,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: i18n.t("candidate")+"s",
    icon: "ni ni-bullet-list-67 text-red",
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/ambassadeurs",
    name: i18n.t("ambassador")+"s",
    icon: "ni ni-bullet-list-67 text-orange",
    component: Ambassadeurs,
    layout: "/admin",
  },
  {
    path: "/partners",
    name: i18n.t("partner")+"s",
    icon: "ni ni-bullet-list-67 text-primary",
    component: Partners,
    layout: "/admin",
  },
  {
    path: "/finances",
    name: "Finances",
    icon: "ni ni-bullet-list-67 text-info",
    component: Finances,
    layout: "/admin",
  }
];
export default routes;