/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================
* Coded by Creative Tim
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,Form,Input,FormGroup,
  Table,
  Container,
  Row,Col,Button,
  Modal, ModalBody, ModalFooter
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Global from '../../constants/Global';

const Finances = (props) => {
  const { t } = props;
  const SweetAlert = withReactContent(Swal)
  const pageSize=10;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false)
  const [listCandidate, setListCandidate] = useState([])

  const [listTransaction, setListTransaction] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  
  const [listFollowUp, setListFollowUp] = useState([])
  const [currentUser, setCurrentUser] = useState({role:"user"})
  const [token, setToken] = useState('')
  const [transactionData, setTransactionData] = useState({
    reason:"",
    follow_up:"",
    amount:"",
    type:"cash_out"
  });
  let history = useHistory();

  const handleSubmit = () => {
    if(!transactionData.reason.length>0){
      NotificationManager.error(t('reason'), t("check_field"));
    }else if(!transactionData.amount.length>0){
      NotificationManager.error(t('amount'), t("check_field"));
    }else{
      setIsLoading(true)
      var _transactionData=transactionData
      _transactionData.token=token
      setModal(false)
  
      listCandidate.forEach(elt => {
        if(elt._id==_transactionData.candidate){
          _transactionData.candidate={
            _id:_transactionData.candidate,
            firstname:elt.firstname,
            lastname:elt.lastname
          }
        }
      });
      
      fetch(Global.SERVER_URL + '/addTransactionData', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(_transactionData)
      }).then(response => response.json())
      .then(responseData => {
        setIsLoading(false)
        if (responseData.hasOwnProperty("error")){
          if (responseData.error === 200){
            NotificationManager.success(t('successful_operation'), 'Message');
          }else NotificationManager.error(responseData.msg, 'Message');
        }
      }, (error) => {
        if (error) {
          NotificationManager.error(t('unknown_error')+" :: ", 'Message');
          setIsLoading(false)
        }
      });
    }
  }

  useEffect(() => {
    document.title = "Finances :: YimmiLine"
    var found = false
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        found = true
        setToken(JSON.parse(localStorage[key]).token)
        setCurrentUser(JSON.parse(localStorage[key]))

        setIsLoading(true)
        fetch(Global.SERVER_URL + '/myListUsers', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({"token":JSON.parse(localStorage[key]).token})
        }).then(response => response.json())
          .then(responseData => {
            if(responseData.error===200) setListCandidate(responseData.list)
            else NotificationManager.error(t('unknown_error'), 'Message');
            setIsLoading(false)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }
          });
          
        fetch(Global.SERVER_URL + '/myFinances', {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({token:JSON.parse(localStorage[key]).token})
        }).then(response => response.json())
          .then(responseData => {
            if(responseData.hasOwnProperty("error"))
              if (responseData.error===200) {
                for (let i = 0; i < responseData.list.length; i++) {
                  if(responseData.list[i].candidate===null)
                  responseData.list[i].candidate={lastname:""}
                  responseData.list[i].reason=""
                }
                setListTransaction(responseData.list.reverse());
              }
            setIsLoading(false)
          }, (error) => {
            if (error) {
              NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }
          });
      }
    if (!found) history.push('/auth/login');
  }, []);
  
  const updateFollowList=(id)=>{
    setTransactionData({ ...transactionData, "candidate":id })
    listCandidate.forEach(candidate => {
      var _listFollowUp=[]
      if(candidate._id===id){
        if(candidate.hasOwnProperty("myFollowUp")){
          candidate.myFollowUp.forEach(myFollowUp => {
            _listFollowUp.push({
              _id:myFollowUp._id,
              name:myFollowUp.type+" "+myFollowUp.option+" ("+myFollowUp.detailType.target_countries+")"
            })
          });
        }
        setListFollowUp(_listFollowUp)
      }
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };


  const deleteRow = (row_id) => {
    SweetAlert.fire({
      title: t("deleting"),
      text: t("confirm_delete"),
      icon: 'error',
      confirmButtonText: t("i_confirm"),
      cancelButtonText: t("no"),
      showCancelButton: true,
    }).then((result) => {
        if(result.isDismissed){}
        else if(result.isConfirmed){
          setIsLoading(true)
          fetch(Global.SERVER_URL + '/deleteFinanceItem', {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({token,row_id})
          }).then(response => response.json())
            .then(responseData => {
              if(responseData.hasOwnProperty("error"))
                if(responseData.error===200){
                  NotificationManager.success(t('successful_operation'), 'Message');
                  window.location.reload()
                }else NotificationManager.error(responseData.msg, 'Message');
              else NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }, (error) => {
              if (error) {
                NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
              }
            });
        }
    })
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <LoadingOverlay
          active={isLoading}
          spinner
          text={t('treatment_progress') + " , " + t('wait_just_moment')}
        >
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="10">
                      <h3 className="mb-0">Finances</h3>
                    </Col>
                    {currentUser.role==="administrator"||currentUser.role==="partner"?<Col lg="2">
                    <Button outline color="primary" onClick={()=>{
                        setTransactionData({...transactionData,
                          reason:"",
                          follow_up:"",
                          amount:"",
                          type:"cash_out"
                        });
                        setModal(!modal)
                      }
                      }>{t("add")}</Button>
                    </Col>:null}
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Motif</th>
                      <th scope="col">Montant</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      listTransaction.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map(elt=>{
                        return (<tr>
                          <td>{ 
                            elt.type!=="cash_out"?
                            moment(elt.date).format("DD,MMM YY HH:mm")+" ("+(elt.candidate.firstname?elt.candidate.firstname:""+" "+elt.candidate.lastname===""?"":elt.candidate.lastname)+")"
                            :
                            moment(elt.date).format("DD,MMM YY HH:mm")
                            }</td>
                          <td>{elt.reason+" ; "+elt.any_additional_information}</td>
                          <td>{elt.amount} EUR</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={elt.type==="cash_out"?"bg-warning":"bg-success" }/>
                              {elt.type==="cash_out"?"Sortie":"Entrée"}
                            </Badge>
                          </td>
                          <td>
                            <Button outline color="danger" size="sm" onClick={()=>deleteRow(elt._id)}>{t("delete")}</Button>
                          </td>
                        </tr>)
                      })
                    }
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination>
                      {[...Array(Math.round(listTransaction.length/pageSize)+1)].map((page, i) => (
                        <PaginationItem active={i === currentPage} key={i}>
                          <PaginationLink onClick={e => handlePageClick(e, i)} href="#">{i + 1}</PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
          <Modal isOpen={modal} toggle={toggle} >
            <ModalBody>
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">{t("candidate_information")}</h6>
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("type_transaction")}</label>
                            <Input type="select"
                              value={transactionData.type}
                              onChange={(val) => {setTransactionData({ ...transactionData, "type": val.target.value })}} >
                              <option value={"cash_out"}>{t("cash_out")}</option>
                              <option value={"cash_in"}>{t("cash_in")}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("amount")} *</label>
                            <Input
                              className="form-control-alternative"
                              type="number" 
                              onChange={(val) => { setTransactionData({ ...transactionData, "amount": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("reason")} *</label>
                            <Input
                              className="form-control-alternative"
                              type="text"
                              value={transactionData.reason}
                              onChange={(val) => { setTransactionData({ ...transactionData, "reason": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {transactionData.type==="cash_in"?<div>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("candidate")}</label>
                              <Input type="select"
                                onChange={(val) => {updateFollowList(val.target.value)}} >
                                <option key="-1" value="-1">-------</option>
                                {listCandidate.map(doc => {
                                  return (<option key={doc._id} value={doc._id}>{doc.firstname+" "+doc.lastname}</option>);
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >{t("follow_up")}</label>
                              <Input type="select"
                                onChange={(val) => {setTransactionData({ ...transactionData, "follow_up": Number(val.target.value) })}} >
                                <option key="-1" value="-1">-------</option>
                                {listFollowUp.map(doc => {
                                  return (<option key={doc._id} value={doc._id}>{doc.name}</option>);
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>:<div></div>}
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >{t("any_additional_information")}</label>
                            <Input
                              className="form-control-alternative"
                              rows="4"
                              type="textarea"
                              onChange={(val) => { setTransactionData({ ...transactionData, "any_additional_information": val.target.value }) }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleSubmit()}>{t("validate")}</Button>{' '}
              <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
            </ModalFooter>
          </Modal>
          <NotificationContainer />
        </LoadingOverlay>
      </Container>
    </>
  );
};

export default withTranslation()(Finances);
