export default {
    SERVER_URL:"https://server.nzui.tech:442",
    validateEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    percent: function (percent) {
        if (percent>70)return "success"
        else if (percent>50) return "info"
        else if (percent>30) return "warning"
        else return "danger"
    },
    getStatus: function (value){
        if (value===0)return "En attente"
        else if (value===1) return "En cours"
        else if (value===2) return "Suspendu"
        else if (value===3) return "VISA obtenu"
        else if (value===4) return "VISA refusé"
        else return " --- "
    
    },
    getState: function (value){
        if (value===10)return "Profilage"
        else if (value===15) return "Recherche admission"
        else if (value===50) return "Admission obtenue"
        else if (value===75) return "Rendez-vous à l'ambassade"
        else if (value===100) return "Terminée"
        else return " --- "
    
    },
    listCountries:["Belgique","Canada","France","Luxembourg","Autre"]
};