import React,{useState,useEffect} from "react";
import Global from '../../constants/Global';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {encode as base64_encode} from 'base-64';

// reactstrap components
import {Button,Card,CardBody,
  FormGroup,Form,Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal, ModalBody, ModalFooter,
} from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const Login = (props) => {
  const {t} = props;

  const [modal, setModal] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [recoverEmail, setRecoverEmail] = useState("")
  const [infos, setInfos] = useState({username:"",password:""});
  let history = useHistory();
  document.title = t('log_into_yimmi')

  useEffect(() => {  
    document.title = t("log_into_yimmi")
    var found=false
    for (var key in localStorage) if(key.includes("myInfos"))found=true
    if(found)history.push('/Admin/Index');
  },[]);

  const handleSubmit=(event)=> {
      if(Global.validateEmail(infos.username)==="") {NotificationManager.error(t('mail_address'), t('empty_field'));
      }else if(infos.password==="") {NotificationManager.error(t('password'), t('empty_field'));
      }else {
        setIsLoading(true)
        fetch(Global.SERVER_URL+'/login', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              username:infos.username,
              password:base64_encode(infos.password)
            })
        }).then(response => response.json())
        .then(responseData=>{
          if(responseData.hasOwnProperty("error")){
  
            if(responseData.error==='200'){
                NotificationManager.success(t('succefull_login'), 'Message');
                localStorage.setItem('myInfos-'+(new Date()).getTime(), JSON.stringify(responseData.data))
                setTimeout(() => {
                    history.push('/Admin/Index');
                }, 2000);
            }
            else if(responseData.error===404)NotificationManager.warning(t("account_no_found"), 'Message');
            else if(responseData.error===405) NotificationManager.warning(t("incorrect_password"), 'Message');
            else  NotificationManager.error(t('unknown_error'), 'Message');
          }else  NotificationManager.error(t('unknown_error'), 'Message');
  
          setIsLoading(false)
        },(error) => {
            if (error) {
              console.log(error);
                NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
            }
          });
      }
      event.preventDefault(); 
  }
  const handleForgetPass=(event)=> {
    if(recoverEmail==="") {NotificationManager.error(t('login_field'), t('empty_field'));
    }else {
      setIsLoading(true)
      
      fetch(Global.SERVER_URL+'/resetPassword', {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({email:recoverEmail})
      }).then(response => response.json())
      .then(responseData=>{
        console.log("responseData",responseData);
        if(responseData.hasOwnProperty("error")){
          if(responseData.error===200){
            setModal(false)
            setRecoverEmail('')
            NotificationManager.success(t('successful_operation'), 'Message');
          }
          else if(responseData.error===404)NotificationManager.warning(t("account_no_found"), 'Message');
          else  NotificationManager.error(t('unknown_error'), 'Message');
        }else  NotificationManager.error(t('unknown_error'), 'Message');

        setIsLoading(false)
      },(error) => {
          if (error) {
            console.log(error);
            NotificationManager.error(t('unknown_error'), 'Message');
            setIsLoading(false)
          }
        });
    }
    event.preventDefault();
  }

  return (
    <>
      <Col lg="5" md="7">
        <LoadingOverlay
            active={isLoading}
            spinner
            text={t('treatment_progress')+" , "+t('wait_just_moment')}
        >
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('login_field')}
                      type="text"
                      value={infos.username}
                      autoComplete="new-email"
                      onChange={(val)=>{
                        setInfos({ ...infos,"username":val.target.value})}} 
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('password')}
                      type={showPwd?"text":"password"}
                      value={infos.password}
                      autoComplete="new-password"
                      onChange={(val)=>{setInfos({ ...infos,"password":val.target.value})}} 
                    />
                    <span className="password__show" onClick={()=>setShowPwd(!showPwd)}>{showPwd? t("hide") : t("show")}</span>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit" >
                  {t("login")}</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                href="#"
                className="text-light"
                onClick={(e) => {
                  setModal(true);
                  e.preventDefault()
                }}
              >
                <small>{t("forgot_password")}</small>
              </a>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={()=>setModal(false)} >
            <ModalBody>
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">{t("forgot_password")}</h6>
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >{t("login_field")}</label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder={t("receive_new_password")}
                              type="text"
                              value={recoverEmail}
                              onChange={(val) => {setRecoverEmail(val.target.value)}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => handleForgetPass(e)}>{t("reset")}</Button>
              <Button color="secondary" onClick={()=>{setModal(false)}}>{t("cancel")}</Button>
            </ModalFooter>
          </Modal>
          <NotificationContainer/>
        </LoadingOverlay>
      </Col>
    </>
  );
};

export default withTranslation()(Login);
