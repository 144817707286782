/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState,useEffect} from "react";
import moment from 'moment';
import Global from '../../constants/Global';
import  "../../views/styles/timeline.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { withTranslation } from 'react-i18next';

const SidebarProfile = (props) => {
  const {t} = props;
  const [myInfos, setMyInfos] = useState({});
  const [stepFollow, setStepFollow] = useState([]);
  
  useEffect(() => {
    if(props.hasOwnProperty("myInfos")){
      setMyInfos(props.myInfos)
      if(props.myInfos.hasOwnProperty("myFollowUp")){
        var myStepFollow=[]
        if(props.myInfos.myFollowUp.hasOwnProperty("eventProcess")){
          props.myInfos.myFollowUp.eventProcess.forEach(process => {
            myStepFollow.push(process)
          });
        }
        setStepFollow(myStepFollow)
      }
    }
  }, []);

  const showRole=(role)=>{
    if (role=="administrator") 
      return (<strong style={{color:"red"}}>{t("administrator")}</strong>)
    else if (role=="ambassador") 
      return (<strong style={{color:"yellow"}}>{t("ambassador")}</strong>)
    else if (role=="user") 
      return (<strong style={{color:"green"}}>{t("candidate")}</strong>)
  }

  return (
    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
      <Card className="card-profile shadow">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <a href="#" onClick={(e) => e.preventDefault()}>
                <img
                  alt="..."
                  className="rounded-circle"
                  src={
                    require("../../assets/img/brand/argon-react.png")
                  }
                />
              </a>
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div className="d-flex justify-content-between"></div>
        </CardHeader>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                <div>
                  <span className="heading">{props.myStat.case}</span>
                  <span className="description">{t("case")}(s)</span>
                </div>
                <div>
                  <span className="heading">{props.myStat.country}</span>
                  <span className="description">{t("country",{count:props.myStat.country})}</span>
                </div>
                <div>
                  <span className="heading">{props.myStat.admission}</span>
                  <span className="description">Admission(s)</span>
                </div>
              </div>
            </div>
          </Row>
          <div className="text-center">
            <hr className="my-4" />
            <div>
              <i className="ni education_hat mr-2" />
              { showRole(myInfos.role)}
            </div>
          </div>
        </CardBody>
      </Card>
          <br/>
      {myInfos.role=="user"?<Card className="card-profile shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{t("progress_status_case")}</h3>
            </Col>
          </Row>
          <hr className="my-4" />
        </CardHeader>
        <Timeline>

        {
          stepFollow.reverse().map(elt=>{
            return(
              <TimelineItem key={elt.date} className="custom-timeline">
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="custom-timeline rs-timeline-item-content">
                  <p>{Global.getStatus(elt.status)}</p>
                  <b>{Global.getState(elt.percent)+" "+elt.percent+"%"}</b>
                  <p>{moment(elt.date).format("DD, MMM YY HH:mm")}</p>
                  <p>{Global.getStatus(elt.note)}</p>
                </TimelineContent>
              </TimelineItem>)
          })
        }
          {/*<TimelineItem className={{}}>
            <TimelineSeparator >
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Typography color="primary" >Admission</Typography></TimelineContent>
          </TimelineItem>*/}
          
        </Timeline>
      </Card>:null}
    </Col>
  );
};
export default withTranslation()(SidebarProfile);
