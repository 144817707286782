/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {Container, Row, Col } from "reactstrap";

const UserHeader = (props) => {
  const [myInfos, setMyInfos] = useState({firstname:" "})
  
  useEffect(() => {
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setMyInfos(JSON.parse(localStorage[key]))
      }
  }, []);
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "6px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/profile-cover.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              <h2 className="display-2 text-white">{props.names?props.names:"Hi "+myInfos.firstname+" "+myInfos.lastname},</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default withTranslation()(UserHeader);