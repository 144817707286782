/*!

=========================================================
* YimmiDashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Container,Badge,
  Row,Button, Col,
  Modal, ModalBody, ModalFooter,
  CardBody, FormGroup, Input,Form
} from "reactstrap";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import Avatar from 'react-string-avatar';
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import validator from 'validator'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from "components/Headers/Header.js";
import Global from '../../constants/Global';

const Ambassadeurs = (props) => {
  const { t } = props;
  const SweetAlert = withReactContent(Swal)
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState({role:"user"})
  const [token, setToken] = useState('')
  
  let history = useHistory();

  const toggle = () => setModal(!modal);
  
  const [infosCurAmbassador, setInfosCurAmbassador] = useState(
    {
      firstname: "",
      lastname: "",
      address_mail: "",
      phone: "",
      role: "ambassador",
      listCountries: [],
    }
  );

  const [ambassadorsList, setAmbassadorsList] = useState([]);
  const columns = [
    {
      dataField: 'names',
      text: "",
      filter: textFilter({placeholder: t("firstname") + ' ' + t("lastname")+" ...",  }),
      formatter: (rowContent, row) => {
        return (<Media className="align-items-center">
          <Avatar string={row.names.split(" ")[0]} width={34} roundShape="true" wrapperStyle={{ border: '4px solid red' }} autoColor={true} />
          <span className="mb-0 text-sm">  {" "+row.names} </span>
        </Media>
        )
      }, 
    }, 
    /*{
      dataField: 'amount',
      text: "Montant perçu",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '8px', textAlign: 'center' };
      }
    },*/
    {
      dataField: 'skill',
      text: t("skill"),
      sort: true
    },
    {
      dataField: 'progress',
      text: t("progress"),
      sort: true,
      formatter: (rowContent, row) => {
        return (<div className="d-flex align-items-center">
          <span className="mr-2">{rowContent.percent}%</span>
          <div>
            <Progress
              max="100"
              value={rowContent.percent}
              barClassName={"bg-gradient-" + Global.percent(rowContent.percent)}
            />
          </div>
          <span className="mr-2">.  {rowContent.percent}</span>
        </div>)
      }
    },
    currentUser.role==="administrator"?{
      dataField: 'id',
      text: '',
      formatter: (rowContent, row) => {
        return (<UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem href="#" onClick={(e) => {
              setInfosCurAmbassador(row)
              toggle()
            }}>
              {t("edit_information")}
            </DropdownItem>
            <DropdownItem onClick={(e) => {
                deleteUser(row._id)
                e.preventDefault()
              }}>
              {t("delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        )
      }
    }:{}
  ];

  const columnsExpand=[ 
    {
      dataField: 'names',
      text: t("candidate"),
      sort: true
    },
    {
      dataField: 'type',
      text: t("type_follow_up"),
      formatter: (rowContent, row) => {
        return (<p>{row.myFollowUp.type+" "+row.myFollowUp.option}</p>
        )
      }
    }, 
    {
      dataField: 'amount',
      text: "Mon. (EUR)",//t("amount_paid"),
      sort: true, 
      headerStyle:  { 'white-space' : 'nowrap' },
      formatter: (rowContent, row) => {
        return (<p>{row.myFollowUp.amount}</p>
        )
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (rowContent, row) => {
        if (row.myFollowUp.status === 0)
          return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />{t("on_hold")}</Badge>)
        else if (row.myFollowUp.status === 1)
          return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-info" />{t("in_progress")}</Badge>)
        else if (row.myFollowUp.status === 2)
          return (<Badge color="" className="badge-dot mr-4">
          <i className="bg-danger" />{t("suspended")}</Badge>)
        else if (row.myFollowUp.status === 3)
          return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-success" />{t("VISA_obtained")}</Badge>)
        else if (row.myFollowUp.status === 4)
          return (<Badge color="" className="badge-dot mr-4">
            <i className="bg-danger" />{t("VISA_refused")}</Badge>)
      }
    },
    {
      dataField: 'percent',
      text: t("progress"),
      sort: true,
      formatter: (rowContent, row) => {
        return (<div className="d-flex align-items-center">
          <span className="mr-2">{row.myFollowUp.progress.percent}%</span>
          <div>
            <Progress
              max="100"
              value={row.myFollowUp.progress.percent}
              barClassName={"bg-gradient-" + Global.percent(row.myFollowUp.progress.percent)}
            />
          </div>
        </div>)
      }
    },
    {
      dataField: '_id',
      text: '',
      formatter: (rowContent, row) => {
        return (<UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => {e.preventDefault()}}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem href="#" onClick={(e) => {
              history.push({
                pathname: '/Admin/candidate-follow-up',
                state: {
                  user_id:row._id,
                  follow_up_id:row.myFollowUp._id
                }
              });
            }}>
              {t("manage_follow_up")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        )
      }
    }
  ];

  const expandRow = {
    renderer: row => (
      <BootstrapTable
        headerWrapperClasses="thead-light"
        wrapperClasses="align-items-center table-flush"
        keyField="_id"
        data={row.followUpDetail}
        columns={columnsExpand}
      />
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands)return <b>-</b>;
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) return (<b>-</b>);
      return <b>+</b>;
    }
  };

  const handleSubmit = () => {
    var showError=""
    if(infosCurAmbassador.lastname!==""){
      if(validator.isEmail(infosCurAmbassador.address_mail)){
        if(infosCurAmbassador.phone!==""){
          if(infosCurAmbassador.listCountries.length!==0){
  
          }else showError=t("target_countries")
        }else showError=t("phone")
      }else showError=t("address_mail")
    }else showError=t("lastname")

    if(showError!==""){
      NotificationManager.error(showError, t("check_field"));
    }else{
      setIsLoading(true)
      setModal(false)

      var _infosCurAmbassador=infosCurAmbassador
      _infosCurAmbassador.token=token
      _infosCurAmbassador.followUpDetail=[]


      fetch(Global.SERVER_URL + '/addAmbassador', {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(_infosCurAmbassador)
      }).then(response => response.json())
        .then(responseData => {
          setModal(true)

          if (responseData.hasOwnProperty("error")) {
            if (responseData.error === 200) {
              setModal(false)
              var _ambassadorsList=ambassadorsList
              _infosCurAmbassador=responseData.data
              _infosCurAmbassador.names=responseData.data.firstname+" "+responseData.data.lastname
              _infosCurAmbassador.amount=0
              _infosCurAmbassador.followUpDetail=[]
              _infosCurAmbassador.progress= { percent: 0, color: "danger" }

              _ambassadorsList.unshift(_infosCurAmbassador);
              setAmbassadorsList(_ambassadorsList)

              NotificationManager.success(t('successful_operation'), 'Message');
            }
            else if (responseData.error === 405) NotificationManager.warning(t("candidate_already_email"), 'Message');
            else NotificationManager.error(t('unknown_error'), 'Message');
          } else NotificationManager.error(t('unknown_error'), 'Message');
  
          setIsLoading(false)
        }, (error) => {
          if (error) {
            NotificationManager.error(t('unknown_error')+"4", 'Message');
            setIsLoading(false)
            setModal(true)
          }
        });
    }
  }
  const deleteUser = (user_id) => {
    SweetAlert.fire({
      title: t("deleting"),
      text: t("confirm_delete"),
      icon: 'error',
      confirmButtonText: t("i_confirm"),
      cancelButtonText: t("no"),
      showCancelButton: true,
    }).then((result) => {
        if(result.isDismissed){}
        else if(result.isConfirmed){
          setIsLoading(true)
          fetch(Global.SERVER_URL + '/deleteUser', {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({token,user_id})
          }).then(response => response.json())
            .then(responseData => {
              if(responseData.hasOwnProperty("error"))
                if(responseData.error===200){
                  NotificationManager.success(t('successful_operation'), 'Message');
                  window.location.reload()
                }else NotificationManager.error(responseData.msg, 'Message');
              else NotificationManager.error(t('unknown_error'), 'Message');
              setIsLoading(false)
            }, (error) => {
              if (error) {
                NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
              }
            });
        }
    })
  }

  useEffect(() => {
    document.title = t('ambassador')+"s :: YimmiLine"
    var found = false
    for (var key in localStorage)
      if (key.includes("myInfos")) {
        setToken(JSON.parse(localStorage[key]).token)
        setCurrentUser(JSON.parse(localStorage[key]))

        if(JSON.parse(localStorage[key]).role!=="user"){
          found = true
          setIsLoading(true)
          fetch(Global.SERVER_URL + '/listAmbassador', {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({"token":JSON.parse(localStorage[key]).token})
          }).then(response => response.json())
            .then(responseData => {
              setAmbassadorsList(responseData)
              setIsLoading(false)
            }, (error) => {
              if (error) {
                NotificationManager.error(t('unknown_error'), 'Message');
                setIsLoading(false)
              }
            });
        }
      }
    if (!found) history.push('/auth/login');
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <LoadingOverlay
        active={isLoading}
        spinner
        text={t('treatment_progress') + " , " + t('wait_just_moment')}
      >
        <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row>
                        <Col className="mb-5 mb-xl-0" xl="10">
                          <h3 className="mb-0">{t("ambassador")}s</h3>
                        </Col>
                        <Col xl="2">
                            {currentUser.role==="administrator"?<Button outline color="primary" onClick={()=>{
                              setInfosCurAmbassador(
                                {
                                  firstname: "",
                                  lastname: "",
                                  address_mail: "",
                                  phone: "",
                                  role: "ambassador",
                                  listCountries: [],
                                }
                              )
                              toggle()
                              }}>{t("add")}</Button>:null}
                        </Col>
                      </Row>
                    </CardHeader>

                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        headerWrapperClasses="thead-light"
                        wrapperClasses="align-items-center table-flush"
                        keyField="_id"
                        bodyStyle={ {width: 300, maxWidth: 300, wordBreak: 'break-all' }}
                        columns={columns}
                        data={ambassadorsList}
                        expandRow={ expandRow }
                        filter={ filterFactory() }
                        pagination={paginationFactory({ 
                          sizePerPage: 7,
                          hideSizePerPage:true,
                        })}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>
            </Row>
          
            <Modal isOpen={modal} toggle={toggle} >
              <ModalBody>
                <Card className="bg-secondary shadow">
                  <CardBody>
                      <Form autoComplete="off" noValidate>
                          <h6 className="heading-small text-muted mb-4">{t("ambassadeur_information")}</h6>
                          <div>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >{t("firstname")}</label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    placeholder={t("your_firstname")}
                                    type="text"
                                    value={infosCurAmbassador.firstname}
                                    onChange={(val) => { setInfosCurAmbassador({ ...infosCurAmbassador, "firstname": val.target.value }) }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                  >{t("lastname")}*</label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-last-name"
                                    placeholder={t("your_lastname")}
                                    type="text"
                                    value={infosCurAmbassador.lastname}
                                    onChange={(val) => { setInfosCurAmbassador({ ...infosCurAmbassador, "lastname": val.target.value }) }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >{t("address_mail")}*</label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-email"
                                    placeholder={t("your") + " " + t("address_mail")}
                                    type="email"
                                    value={infosCurAmbassador.address_mail}
                                    onChange={(val) => { setInfosCurAmbassador({ ...infosCurAmbassador, "address_mail": val.target.value }) }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-phone"
                                  >{t("phone")}*</label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-phone"
                                    placeholder={t("your_phone")}
                                    type="phone"
                                    value={infosCurAmbassador.phone}
                                    onChange={(val) => { setInfosCurAmbassador({ ...infosCurAmbassador, "phone": val.target.value }) }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                  >Role*</label>

                                  <Input type="select"
                                    value={infosCurAmbassador.role}
                                    onChange={(val) => { setInfosCurAmbassador({ ...infosCurAmbassador, "role": val.target.value }) }} >
                                    <option value={"ambassador"}>{t("ambassador")}</option>
                                    <option value={"administrator"}>{t("administrator")}</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <label className="form-control-label">{t("skill")}s*</label>
                                  <Input type="select" multiple 
                                    value={infosCurAmbassador.listCountries}
                                    onChange={(val) => { 
                                      let value = Array.from(val.target.selectedOptions, option => option.value);
                                      setInfosCurAmbassador({...infosCurAmbassador,'listCountries':value}) }} >
                                      {Global.listCountries.map((country)=>{
                                        return (<option value={country}>{country}</option>)
                                      })}
                                  </Input>
                                  <p className="mt-3 mb-0 text-muted text-sm">
                                      <span className="text-nowrap">{t("CTRL_click")}</span>
                                  </p>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                      </Form>
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => handleSubmit()}>{t("validate")}</Button>{' '}
                <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
              </ModalFooter>
            </Modal>

            <NotificationContainer />
        </Container>
      </LoadingOverlay>
    </>
  );
};
export default withTranslation()(Ambassadeurs);
