/*!
=========================================================
* YimmiDashboard React - v1.2.0
=========================================================
* Coded by Creative Tim
=========================================================
*/
import React, { useState,useEffect,useRef } from "react";
// node.js library that concatenates classes (strings)
import { useHistory } from 'react-router-dom';
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {Card,CardHeader,
  CardBody,CardTitle,NavItem,
  NavLink,Nav, Progress,
  Table,  Container, Row,Col,
  PaginationLink,PaginationItem,Pagination
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withTranslation } from 'react-i18next';
import Global from '../constants/Global';

const Index = (props) => {
  const {t} = props;
  const tableCandidateSize=5;

  const [activeNav, setActiveNav] = useState(1);
  const [isLoading, setIsLoading] = useState(true)
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [data_dashboard, setDataDashboard] = useState({
    nb_user_last_year: "--",
    nb_user_this_year: "--",
    nb_user_last_month: "--",
    nb_user_this_month: "--",
    ambassador: {},
    listCase: [
      {
        fullname: '---',
        ambassador: '----, ',
        status: 1,
        percent: 15,
        date: "2021-09-09T20:27:51.974Z",
        country: 'France'
      }
    ],
    nb_followUp_last_year: "--",
    nb_followUp_this_year: "--",
    nb_followUp_last_month: "--",
    nb_followUp_this_month: "--",
    admission: [],
    cash_in_last_year: "--",
    cash_in_this_year: "--",
    cash_in_last_month: "--",
    cash_in_this_month: "--",
    dash_line: {
      nb_user: [
        0, 0, 0, 0,
        1, 0, 0
      ],
      sum_cash_in: [
        0, 437, 0, 0,
        0,   0, 0
      ],
      listMonth: [
        'Jan', 'Dec',
        'Nov', 'Oct',
        'Sep', 'Aug',
        'Jul'
      ]
    },
    cash_out_last_year: "--",
    cash_out_this_year: "--",
    cash_out_last_month: "--",
    cash_out_this_month: "--"
  })

  const [candidateView, setCandidateView] = useState(0);

  let history = useHistory();
  const mySession= useRef({});
    
  useEffect(() => {
      document.title = t("dashboard")+" :: YimmiLine"
      var found=false
      for (var key in localStorage){
          if(key.includes("myInfos")){
            mySession.current=JSON.parse(localStorage.getItem(key))
            found=true

            setIsLoading(true)
            fetch(Global.SERVER_URL + '/dashboard', {
              method: 'post',
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
              },
              body: JSON.stringify({token:mySession.current.token})
            }).then(response => response.json())
              .then(responseData => {
                if(responseData.hasOwnProperty("error")){
                  if(responseData.error===200)
                  setDataDashboard(responseData.dashboard);
                }
                setIsLoading(false)
              }, (error) => {
                if (error) {
                  NotificationManager.error(t('unknown_error'), 'Message');
                  setIsLoading(false)
                }
              });
          }
      }
      if(!found)history.push('/auth/login');
  },[]);

  if (window.Chart) {parseOptions(Chart, chartOptions());}

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  
  const handleTableCandidateClick = (e, index) => {
    e.preventDefault();
    setCandidateView(index);
  };

  return (
    <>
      <LoadingOverlay
          active={isLoading}
          spinner
          text={t('treatment_progress') + " , " + t('wait_just_moment')}
      >
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                {mySession.current.role!=="user"?<Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          > Nouveaux candidats
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {data_dashboard.nb_user_this_year}
                            <span className="h5 mb-0"> / {data_dashboard.nb_user_last_year}</span>
                          </span> <span className="h5 mb-0"> Annuel</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={data_dashboard.nb_user_this_month>=data_dashboard.nb_user_last_month?"h4 text-success mr-2":"h4 text-warning mr-2"}>
                          <i className={data_dashboard.nb_user_this_month>=data_dashboard.nb_user_last_month?"fa fa-arrow-up":"fa fa-arrow-down"} /> {data_dashboard.nb_user_this_month}
                          <span className="h5 text-nowrap"> / {data_dashboard.nb_user_last_month}</span>
                        </span>{" "}
                          <span className="text-nowrap">Mensuel</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>:null}
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Nouveaux Dossiers
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {data_dashboard.nb_followUp_this_year}
                            <span className="h5 mb-0"> / {data_dashboard.nb_followUp_last_year}</span>
                          </span> <span className="h5 mb-0"> Annuel</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={data_dashboard.nb_followUp_this_month>=data_dashboard.nb_followUp_last_month?"h4 text-success mr-2":"h4 text-warning mr-2"}>
                          <i className={data_dashboard.nb_followUp_this_month>=data_dashboard.nb_followUp_last_month?"fa fa-arrow-up":"fa fa-arrow-down"} /> {data_dashboard.nb_followUp_this_month}
                          <span className="h5 text-nowrap"> / {data_dashboard.nb_followUp_last_month}</span>
                        </span>{" "}
                          <span className="text-nowrap">Mensuel</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                {mySession.current.role==="administrator" || mySession.current.role==="partner"?<Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >Recettes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {data_dashboard.cash_in_this_year}
                            <span className="h5 mb-0"> / {data_dashboard.cash_in_last_year}</span>
                          </span> <span className="h5 mb-0"> Annuel</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={data_dashboard.cash_in_this_month>=data_dashboard.cash_in_last_month?"h4 text-success mr-2":"h4 text-warning mr-2"}>
                          <i className={data_dashboard.cash_in_this_month>=data_dashboard.cash_in_last_month?"fa fa-arrow-up":"fa fa-arrow-down"} /> {data_dashboard.cash_in_this_month}
                          <span className="h5 text-nowrap"> / {data_dashboard.cash_in_last_month}</span>
                        </span>{" "}
                          <span className="text-nowrap">Mensuel</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>:null}
                {mySession.current.role!=="ambassador"?<Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Dépenses
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {data_dashboard.cash_out_this_year}
                            <span className="h5 mb-0"> / {data_dashboard.cash_out_last_year}</span>
                          </span> <span className="h5 mb-0"> Annuel</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={data_dashboard.cash_out_this_month>=data_dashboard.cash_out_last_month?"h4 text-success mr-2":"h4 text-warning mr-2"}>
                          <i className={data_dashboard.cash_out_this_month>=data_dashboard.cash_out_last_month?"fa fa-arrow-up":"fa fa-arrow-down"} /> {data_dashboard.cash_out_this_month}
                          <span className="h5 text-nowrap"> / {data_dashboard.cash_out_last_month}</span>
                        </span>{" "}
                          <span className="text-nowrap">Mensuel</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>:null}
              </Row>
            </div>
          </Container>
        </div>
      
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {mySession.current.role==="administrator" || mySession.current.role==="partner"?<Col className="mb-5 mb-xl-0" xl="8">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col xl="4">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Aperçu
                        </h6>
                        <h2 className="text-white mb-0">Progression</h2>
                      </Col>
                      <Col xl="8">
                        <Nav className="justify-content-end" pills>
                          <NavItem>
                            <NavLink
                              className={classnames("py-2 px-3", {
                                active: activeNav === 1,
                              })}
                              href="#"
                              onClick={(e) => toggleNavs(e, 1)}
                            >
                              <span className="d-none d-md-block">Souscription</span>
                              <span className="d-md-none">Spt</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames("py-2 px-3", {
                                active: activeNav === 2,
                              })}
                              data-toggle="tab"
                              href="#"
                              onClick={(e) => toggleNavs(e, 2)}
                            >
                              <span className="d-none d-md-block">Recette</span>
                              <span className="d-md-none">Rct</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames("py-2 px-3", {
                                active: activeNav === 3,
                              })}
                              data-toggle="tab"
                              href="#"
                              onClick={(e) => toggleNavs(e, 3)}
                            >
                              <span className="d-none d-md-block">Dépense</span>
                              <span className="d-md-none">Dép</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Line
                        data={chartExample1Data==="data1"?{
                          labels: data_dashboard.dash_line.listMonth,
                          datasets: [{label: "candidat(s)",
                              data: data_dashboard.dash_line.nb_user,
                          }]}:chartExample1Data==="data2"?{
                            labels: data_dashboard.dash_line.listMonth,
                            datasets: [{label: "eur",
                                data: data_dashboard.dash_line.sum_cash_in,
                            }]}:{
                              labels: data_dashboard.dash_line.listMonth,
                              datasets: [{label: "eur",
                                  data: data_dashboard.dash_line.sum_cash_out,
                              }]}}
                          
                        options={chartExample1.options}
                        getDatasetAtEvent={(e) => console.log(e)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>:<Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{t("case")}s</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t("candidate")}</th>
                      <th scope="col">{t("country")}</th>
                      <th scope="col">{t("ambassador")}</th>
                      <th scope="col">{t("progress")}</th>
                    </tr>
                  </thead>
                  <tbody>

                  {data_dashboard.listCase.reverse().map(element => {
                      return (<tr key={element.fullname}>
                        <th scope="row">{element.fullname}</th>
                        <td>{element.country}</td>
                        <td>{element.ambassador}</td>
                        <td>{element.percent}%</td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>}
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Ambassadeurs</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Bar
                      data={data_dashboard.ambassador}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {mySession.current.role!=="user"?<Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              {mySession.current.role==="administrator" || mySession.current.role==="partner"?<Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{t("case")}s</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t("candidate")}</th>
                      <th scope="col">{t("country")}</th>
                      <th scope="col">{t("ambassador")}</th>
                      <th scope="col">{t("progress")}</th>
                    </tr>
                  </thead>
                  <tbody>

                  {data_dashboard.listCase.slice(candidateView * tableCandidateSize, (candidateView + 1) * tableCandidateSize).map(element => {
                      return (<tr key={element.fullname}>
                        <th scope="row">{element.fullname}</th>
                        <td>{element.country}</td>
                        <td>{element.ambassador}</td>
                        <td>{element.percent}%</td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
                <Pagination>
                  {[...Array(Math.round(data_dashboard.listCase.length/tableCandidateSize)+1)].map((page, i) => (
                    <PaginationItem active={i === candidateView} key={i}>
                      <PaginationLink onClick={e => handleTableCandidateClick(e, i)} href="#">{i + 1}</PaginationLink>
                    </PaginationItem>
                  ))}
                </Pagination>
              </Card>:null}
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Admission</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Pays</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    
                  {data_dashboard.admission.map(element => {
                      return (<tr key={element.country}>
                        <th scope="row">{element.country+" ("+element.done+"/"+element.total+")"}</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">{Math.round((element.done/element.total)*100)}%</span>
                            <div>
                              <Progress
                                max="100"
                                value={Math.round((element.done/element.total)*100)}
                                barClassName={"bg-gradient-"+Global.percent((element.done/element.total)*100)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>:null}
        </Container>
        <NotificationContainer />
      </LoadingOverlay>
    </>
  );
};
export default withTranslation()(Index);
